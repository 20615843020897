import React, { useState, useMemo } from 'react'
import { Modal, Button, Form } from "semantic-ui-react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {  getAllSavedSimulations, 
          changeSimulationMaterialData, 
          changeSimulationQuantityModifier,
          changeSimulationMaterialGroupSetting,
          changeSimulationGreyZoneArray,
          changeSimAduFreqUpd,
          changeSimOrdDays,
          changeLoadSelectedData,
          changeLoadSimulationData
         } from '../../../../actions/analytics-v2'
import "./ModalSimulationMenu.css"

// TODO: Refactor this code if it is possible, we have to move to new table component 
import { useTable } from 'react-table'
import { mapColumnHeaderToReactTableFormat } from '../../../../utils/mappers/analytics'
import { CustomTableStyle } from './CustomTableStyle'
import Table from '../../../DataImport/Table/Table'
import EditName from './EditName/EditName'
import DeleteButton from './DeleteButton/DeleteButton'

const ModalSimulationMenu = 
({  headerName, 
    positiveButtonName, 
    iconName, 
    simulationsSavedList,
    disabled,
    positiveButtonAction,
    selectRowsTable=false,
    changeSimulationMaterialData,
    changeSimulationQuantityModifier,
    changeSimulationMaterialGroupSetting,
    changeSimulationGreyZoneArray,
    changeSimAduFreqUpd,
    changeSimOrdDays,
    changeLoadSelectedData,
    loadedSelectedSimulationData,
    changeLoadSimulationData,
}) => {
    const { t } = useTranslation()
    const [ open, setOpen ] = useState(false)
    const [ inputName, setInputName ] = useState("")
    const [ errorInput, setErrorInput ] = useState(false)
    const [ selectedRowData, setSelectedRowData ] = useState({})


    const [ editMyData, setEditMyData ] = useState()
    const [ deleteData, setDeleteData ] = useState("")
    const [ modifyErrors, setModifyErrors ] = useState("")
    const [ disableActionButtons, setDisableActionButtons ] = useState(false)

    const translateMapper = useMemo(()=>
                                  {
                                    return({
                                        sim_name: t("simulation.simulationName"),
                                        material_id: t("simulation.materialId"),
                                        created_at: t("simulation.createdAt"),
                                    })
                                  }, [t])
    
    const columns = useMemo(() => 
        {
          if(simulationsSavedList.length){
            if(headerName===t("simulation.modify")){
              return mapColumnHeaderToReactTableFormat(simulationsSavedList[0], translateMapper, "sim_id")
            }

            return mapColumnHeaderToReactTableFormat(simulationsSavedList[0], translateMapper, "sim_id", false)
          }
          return []
        },
        [simulationsSavedList, translateMapper, headerName, t]
    )

    const editDataAction = (data)=>{
      setEditMyData(data)
      setDisableActionButtons(true)
      setModifyErrors("")
    }

    const deleteDataAction = (data)=>{
      setDeleteData(data)
      setDisableActionButtons(true)
      setModifyErrors("")
    }
    
    const data = useMemo(() => 
    {
      if(simulationsSavedList.length){
        if(headerName===t("simulation.modify")){
          return simulationsSavedList
                      .map((rowData)=>({
                        sim_name: <EditName 
                                      rowData={rowData}
                                      onEditClick={(data)=>editDataAction(data)}
                                      disableComponent={disableActionButtons}
                                      cancelAction={(status)=>setDisableActionButtons(status)}/>,
                        material_id: rowData.material_id,
                        created_at: rowData.created_at, 
                        modify: <DeleteButton 
                                      rowData={rowData}
                                      onDeleteClick={(data)=>deleteDataAction(data)}
                                      disableComponent={disableActionButtons}
                                      cancelAction={(status)=>setDisableActionButtons(status)}/>
                      }))
        }
        // eslint-disable-next-line no-unused-vars
        return simulationsSavedList.map(({sim_id, ...otherSimSaved}) => otherSimSaved)
      }
    },
      [simulationsSavedList, headerName, disableActionButtons, t]
    )

    const customTable = useTable({columns, data})

    const resetAllData = ()=>{
      setInputName("")
      setErrorInput(false)
      setEditMyData({})
      setDeleteData("")
      setModifyErrors("")
      setDisableActionButtons(false)
    }


    const saveAction = () => {
      const simulationNames = simulationsSavedList
                                        .map((simulationData)=> simulationData.sim_name)
                                        .some((simulationName)=> inputName.includes(simulationName))
    

      if(simulationNames){
            setErrorInput({
            content: t("simulation.nameExists"),
            pointing: 'left',
            })
            return
      }

      positiveButtonAction(inputName)
      resetAllData()
      setOpen(false)
    }

    const loadAction = () => {
      if(selectedRowData.save_name || selectedRowData.material_id){
        positiveButtonAction( 
          selectedRowData.material_id,
          selectedRowData.save_name, 
        )
        changeLoadSelectedData(selectedRowData.save_name, selectedRowData.material_id, selectedRowData.sim_id)
        // Reset selected value
        setSelectedRowData({})
        //Reset all values from simulation, quantity, group settings, grey zone, order days, sim orders
        changeSimulationMaterialData({
          name: "",
          data: ""
        })
        changeSimulationQuantityModifier([])
        changeSimulationMaterialGroupSetting({})
        changeSimulationGreyZoneArray([])
        changeSimAduFreqUpd(1)
        changeSimOrdDays([0, 1, 2, 3, 4, 5, 6])

        setOpen(false)
      }
    }

    const modifyAction = () => {
     
      if(editMyData && editMyData.save_name){
        positiveButtonAction("edit", editMyData.sim_id, editMyData.material_id, editMyData.save_name)
        // This line is to change the simulation name of the selected load data
        if(Object.keys(loadedSelectedSimulationData).length){
          changeLoadSelectedData(editMyData.save_name, editMyData.material_id, editMyData.sim_id)
        }
        resetAllData()
        setOpen(false)
        return
      }
      
      if(deleteData){
        positiveButtonAction("delete", deleteData, "", "")
        if(loadedSelectedSimulationData.sim_id && loadedSelectedSimulationData.sim_id === deleteData){
          changeLoadSimulationData([])
          changeLoadSelectedData({})
        }
        resetAllData()
        setOpen(false)
        return
      }
      
      if(!deleteData && !editMyData){
        setModifyErrors(t("simulation.noData"))
      }
     
    }

    const acceptHandler = () => {
      if(headerName=== t("save")){
        saveAction()
      }

      if(headerName=== t("simulation.load")){
        loadAction()
      }


      if(headerName===t("simulation.modify")){
        modifyAction()
      }
    }

    const cancelHandler = ()=>{
      setOpen(false) 
      setDisableActionButtons(false) 
      setModifyErrors("")
    }

    const selectRowHandler = (rowData)=>{
      const objectRowData = rowData.original
      //TODO: this code to extract sim_id could be inproved if you change data from react table input but maybe design would be changed badly
      const selectedSimId = simulationsSavedList
                                    .find((objData)=> objData.sim_name === objectRowData.sim_name).sim_id
      if(objectRowData){
        setSelectedRowData({
            material_id: objectRowData.material_id,
            save_name: objectRowData.sim_name,
            sim_id: selectedSimId
        })
      }
    }
    
    return(
        <Modal
            onClose={()=>setOpen(false)}
            onOpen = {()=>{
                setOpen(true)
            }}
            open={open}
            trigger={
                <Button 
                  content={headerName} 
                  icon={iconName} 
                  labelPosition='left' 
                  disabled={disabled}
                  className="save-simulation-button"/>
            }
            >

            <Modal.Header>{headerName}</Modal.Header>

            <Modal.Content>

                { 
                  simulationsSavedList.length ? <CustomTableStyle>
                                                      <Table
                                                        instance={customTable}
                                                        onSelectRow={(row)=>selectRowHandler(row)}
                                                        allowSelectRow={selectRowsTable}
                                                        />
                                                  </CustomTableStyle>
                                              : null
                }
                {
                  modifyErrors && <label className='error-simulation-modify'>{modifyErrors}</label>
                }
                {
                  headerName === t("save")
                    && <Form className='form-save-simulation'>
                        <Form.Input
                          placeholder={t("simulation.simulationName")}
                          name='simulationName'
                          value={inputName}
                          label={t("groupSettings.nameLabel")}
                          labelPosition='left'
                          onChange={(e, data) => setInputName(data.value)}
                          error={errorInput}
                          className="form-save-simulation-input"
                        />
                      </Form>
                }
            </Modal.Content>

            <Modal.Actions>
                <Button color='black' 
                    onClick={() => cancelHandler()}>
                    {t("cancel")}
                </Button>
                <Button
                    onClick={
                        (e) => {
                          e.preventDefault(); 
                          acceptHandler()}
                    }
                    positive>
                    {positiveButtonName}
                </Button>
            </Modal.Actions>

        </Modal>
    )
}

ModalSimulationMenu.propTypes = {
    headerName: PropTypes.string,
    positiveButtonName : PropTypes.string,
    iconName: PropTypes.string,
    currentWorkspaceId: PropTypes.number,
    getAllSavedSimulations: PropTypes.func,
    simulationsSavedList: PropTypes.array,
    disabled: PropTypes.bool,
    loadingSimulation: PropTypes.bool,
    positiveButtonAction: PropTypes.func,
    selectRowsTable: PropTypes.bool,
    changeSimulationMaterialData: PropTypes.func,
    changeSimulationQuantityModifier: PropTypes.func,
    changeSimulationMaterialGroupSetting: PropTypes.func,
    changeSimulationGreyZoneArray: PropTypes.func,
    changeSimAduFreqUpd: PropTypes.func,
    changeSimOrdDays: PropTypes.func,
    changeLoadSelectedData: PropTypes.func,
    loadedSelectedSimulationData: PropTypes.object,
    changeLoadSimulationData: PropTypes.func,
}

const mapStateToProps = state => ({
    simulationsSavedList: state.analytics.simulationsSavedList,
    loadingSimulation: state.analytics.loadingSimulation,
    loadedSelectedSimulationData: state.analytics.loadedSelectedSimulationData,
})


export default connect(  mapStateToProps, 
                      {  getAllSavedSimulations, 
                          changeSimulationMaterialData, 
                          changeSimulationQuantityModifier,
                          changeSimulationMaterialGroupSetting,
                          changeSimulationGreyZoneArray,
                          changeSimAduFreqUpd,
                          changeSimOrdDays,
                          changeLoadSelectedData,
                          changeLoadSimulationData
                        } )(ModalSimulationMenu)
