// Items
export const GET_ITEMS = "GET_ITEMS";
export const GET_BATCH_DATA = "GET_BATCH_DATA";
export const BEGIN_ITEMS_FETCH = "BEGIN_ITEMS_FETCH";
export const ABORT_ITEMS_FETCH = "ABORT_ITEMS_FETCH";
export const EDIT_ACTUAL_ORDER = "EDIT_ACTUAL_ORDER";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const START_ACTUAL_ORDER_UPDATE = "START_ACTUAL_ORDER_UPDATE";
export const END_ACTUAL_ORDER_UPDATE = "END_ACTUAL_ORDER_UPDATE";

// Alerts
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_ERROR = "SET_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Orders Table
export const GET_LAYOUTS = "GET_LAYOUTS";
export const SELECT_LAYOUT = "SELECT_LAYOUT";
export const SAVE_LAYOUT = "SAVE_LAYOUT";
export const DELETE_LAYOUT = "DELETE_LAYOUT";
export const EDIT_LAYOUT = "EDIT_LAYOUT";
export const RESET_ORDER_CALCULATIONS_METADATA =
"RESET_ORDER_CALCULATIONS_METADATA";
export const UPDATE_ORDER_CALCULATIONS_METADATA =
"UPDATE_ORDER_CALCULATIONS_METADATA";
export const SUBMIT_ACTUAL_ORDERS = "UPDATE_COMMITTED_STATUS";
export const BEGIN_TABLE_UPDATE = "BEGIN_TABLE_UPDATE";
export const ABORT_TABLE_UPDATE = "ABORT_TABLE_UPDATE";
export const END_TABLE_UPDATE = "END_TABLE_UPDATE";
export const SET_NEW_CALCULATION_REQUIRED = "SET_NEW_CALCULATION_REQUIRED";
export const SET_ORDER_ERRORS = "SET_ORDER_ERRORS";
export const SET_NOTIFICATION_PANEL_FILTER = "SET_NOTIFICATION_PANEL_FILTER";
export const SET_ORDER_TABLE_FILTERS = "SET_ORDER_TABLE_FILTERS";
export const UPDATE_TABLE_ORDER = "UPDATE_TABLE_ORDER"
export const UPDATE_TABLE_CELL_VALUE = "UPDATE_TABLE_CELL_VALUE";
// Rows
export const SELECT_ROW = "SELECT_ROW";
export const SELECT_ACTUAL_ORDER_CELL = "SELECT_ACTUAL_ORDER_CELL"
export const SET_ORDER_TABLE_CONTAINER = "SET_ORDER_TABLE_CONTAINER";

// Calendar
export const GET_EVENTS = "GET_EVENTS";
export const ADD_EVENT = "ADD_EVENT";
export const EDIT_EVENT = "EDIT_EVENT";
export const REMOVE_EVENT = "REMOVE_EVENT";
export const BEGIN_EVENT_FETCH = "BEGIN_EVENT_FETCH";
export const ABORT_EVENTS_REQUEST = "ABORT_EVENTS_REQUEST";
export const BEGIN_EVENT_REQUEST = "BEGIN_EVENT_REQUEST";
export const SELECT_VISIBLE_WORKSPACE_EVENTS = "SELECT_VISIBLE_WORKSPACE_EVENTS"

// Data Import
export const GET_UPLOAD_HISTORY = "GET_UPLOAD_HISTORY";
export const UPDATE_UPLOAD_HISTORY = "UPDATE_UPLOAD_HISTORY";
export const GET_FILETYPE_LAST_UPDATED = "GET_FILETYPE_LAST_UPDATED";
export const BEGIN_FILETYPE_LAST_UPDATED_FETCH =
  "BEGIN_FILETYPE_LAST_UPDATED_FETCH";
export const ABORT_FILETYPE_LAST_UPDATED_FETCH =
  "ABORT_FILETYPE_LAST_UPDATED_FETCH";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPDATE_FILE_STATUS = "UPDATE_FILE_STATUS";
export const ABORT_FILE_UPLOAD = "ABORT_FILE_UPLOAD";
export const BEGIN_UPLOAD_HISTORY_FETCH = "BEGIN_UPLOAD_HISTORY_FETCH";
export const ABORT_UPLOAD_HISTORY_FETCH = "ABORT_UPLOAD_HISTORY_FETCH";
export const CREATE_AUTO_UPLOAD_CONFIG_SUCCESS = "CREATE_AUTO_UPLOAD_CONFIG_SUCCESS";
export const CREATE_AUTO_UPLOAD_CONFIG_FAIL = 'CREATE_AUTO_UPLOAD_CONFIG_FAIL'
export const CREATE_AUTO_UPLOAD_CONFIG = "CREATE_AUTO_UPLOAD_CONFIG";
export const GET_BUCKET_NAME = "GET_BUCKET_NAME";
export const GET_NEW_KEY_SUCCESS = 'GET_NEW_KEY_SUCCESS'
export const GET_NEW_KEY_FAIL = 'GET_NEW_KEY_FAIL'
export const GET_NEW_KEY = "GET_NEW_KEY";
export const FLUSH_KEY = 'FLUSH_KEY';
export const CREATING_NEW_INT_API_TOKEN = "CREATING_NEW_INT_API_TOKEN";
export const CREATING_NEW_INT_API_TOKEN_SUCCESS = "CREATING_NEW_INT_API_TOKEN_SUCCESS"
export const CREATING_NEW_INT_API_TOKEN_FAIL = 'CREATING_NEW_INT_API_TOKEN_FAIL';
export const INT_API_TOKENS_INFO = "INT_API_TOKENS_INFO";

// Buffer Settings
export const GET_GROUPS = "GET_GROUPS";
export const GET_FATHER_GROUPS = "GET_FATHER_GROUPS";
export const SAVE_GROUP = "SAVE_GROUP";
export const EDIT_GROUP = "EDIT_GROUP";
export const REMOVE_GROUP = "REMOVE_GROUP";
export const GET_DATASET = "GET_DATASET";
export const ABORT_DATASET_FETCH = "ABORT_DATASET_FETCH";
export const ABORT_GROUPS_REQUEST = "ABORT_GROUPS_REQUEST";
export const BEGIN_GROUPS_REQUEST = "BEGIN_GROUPS_REQUEST";
export const BEGIN_GROUP_FETCH = "BEGIN_GROUP_FETCH";

// User Management
export const AUTH_ERROR = "AUTH_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const USER_LOADED = "USER_LOADED";
export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";
export const TOKEN_REFRESH_FAIL = "TOKEN_REFRESH_FAIL";
export const ADMIN_CONSOLE_ACCESS_GRANTED = "ADMIN_CONSOLE_ACCESS_GRANTED";
export const SESSION_REFRESH_REQUIRED = "SESSION_REFRESH_REQUIRED";
export const INVITE_LINK_GENERATED = "INVITE_LINK_GENERATED";
export const BEGIN_INVITE_LINK_FETCH = "BEGIN_INVITE_LINK_FETCH";
export const ABORT_INVITE_LINK_FETCH = "ABORT_INVITE_LINK_FETCH";
export const SET_CONNECTION_STATUS = "SET_CONNECTION_STATUS";
export const SET_PASSWORD_ICON = "SET_PASSWORD_ICON"
export const BEGIN_AUTH_RECOVER_PASS_TOKEN = "BEGIN_AUTH_RECOVER_PASS_TOKEN"
export const END_AUTH_RECOVER_PASS_TOKEN = "END_AUTH_RECOVER_PASS_TOKEN"
export const RECOVER_PASS_MESSAGE = "RECOVER_PASS_MESSAGE"

// Organization
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const GET_ORGANIZATION = "GET_ORGANIZATION";
export const GET_WORKSPACES = "GET_WORKSPACES";
export const UPDATE_WORKSPACE_SETTING = "UPDATE_WORKSPACE_SETTING";
export const BEGIN_WORKSPACE_REQUEST = "BEGIN_WORKSPACE_REQUEST";
export const ABORT_WORKSPACE_REQUEST = "ABORT_WORKSPACE_REQUEST";
export const CREATE_WORKSPACE = "CREATE_WORKSPACE";
export const UPDATE_WORKSPACES = "UPDATE_WORKSPACES";
export const EDIT_WORKSPACE = "EDIT_WORKSPACE";
export const DELETE_WORKSPACE = "DELETE_WORKSPACE";
export const SET_WORKSPACE_TO_DELETE = "SET_WORKSPACE_TO_DELETE";
export const GET_ROLES = "GET_ROLES";
export const ADD_ROLES = "ADD_ROLES";
export const REMOVE_ROLES = "REMOVE_ROLES";
export const SELECT_WORKSPACE = "SELECT_WORKSPACE";
export const RESET_LOADING_WORKSPACES_FLAG = "RESET_LOADING_WORKSPACES_FLAG";
export const SET_SELECTOR_DISABLED = "SET_SELECTOR_DISABLED";
export const DELETE_USER = "DELETE_USER";
export const BEGIN_ORGANIZATION_FETCH = "BEGIN_ORGANIZATION_FETCH";
export const ABORT_ORGANIZATION_FETCH = "ABORT_ORGANIZATION_FETCH";
export const GET_ADVANCED_SETTINGS = "GET_ADVANCED_SETTINGS";

//Analytics
export const GET_ANALYTICS_FILTERS = "GET_ANALYTICS_FILTERS";
export const SET_NEW_GROUP_DATA = "SET_NEW_GROUP_DATA";
export const GET_ANALYTICS_PASS = 'GET_ANALYTICS_PASS';
export const SET_TOGGLE_OPTIONS = "SET_TOGGLE_OPTIONS"
export const SET_ANALYTICS_MATERIAL_DATA = "SET_ANALYTICS_MATERIAL_DATA" 
export const BEGIN_ANALYZE = "BEGIN_ANALYZE"
export const END_ANALYZE = "END_ANALYZE"
export const ANALYTICS_ALLOWED_STATUS = "ANALYTICS_ALLOWED_STATUS"
export const QUERY_ANALYTICS_BY_MATERIAL_REPORT = "QUERY_ANALYTICS_BY_MATERIAL_REPORT"
export const QUERY_ANALYTICS_REPORT = "QUERY_ANALYTICS_REPORT"
export const QUERY_ANALYTICS_ALL_WORKSPACES_REPORT = "QUERY_ANALYTICS_ALL_WORKSPACES_REPORT"
export const GET_ANALYTICS_CURRENT_WORKSPACE_TIME_FILTER = "GET_ANALYTICS_CURRENT_WORKSPACE_TIME_FILTER"
export const GET_ANALYTICS_ALL_WORKSPACES_TIME_FILTER = "GET_ANALYTICS_ALL_WORKSPACES_TIME_FILTER"
export const SET_USER_SELECTED_ANALYTICS_TIME_FILTER = "SET_USER_SELECTED_ANALYTICS_TIME_FILTER"
export const SET_AGGREGATE_TIME_FILTER = "SET_AGGREGATE_TIME_FILTER"
export const SET_SINGLE_ITEM_TIME_FILTER = "SET_SINGLE_ITEM_TIME_FILTER"
export const SET_ALL_WORKSPACES_TIME_FILTER = "SET_ALL_WORKSPACES_TIME_FILTER"
export const GET_ANALYTICS_CURRENT_WORKSPACE_FIELD_FILTERS = 'GET_ANALYTICS_CURRENT_WORKSPACE_FIELD_FILTERS'
export const GET_ANALYTICS_ALL_WORKSPACES_FIELD_FILTERS = 'GET_ANALYTICS_ALL_WORKSPACES_FIELD_FILTERS'
export const SET_ANALYTICS_CURRENT_WORKSPACE_FIELD_FILTERS_PROPERTIES = "SET_ANALYTICS_CURRENT_WORKSPACE_FIELD_FILTERS_PROPERTIES"
export const SET_ANALYTICS_ALL_WORKSPACES_FIELD_FILTERS_PROPERTIES = "SET_ANALYTICS_ALL_WORKSPACES_FIELD_FILTERS_PROPERTIES"
export const SET_SELECTED_ALL_WORKSPACES_FIELD_FILTER_DATA = "SET_SELECTED_ALL_WORKSPACES_FIELD_FILTER_DATA"
export const SET_SELECTED_CURRENT_WORKSPACE_FIELD_FILTER_DATA = "SET_SELECTED_CURRENT_WORKSPACE_FIELD_FILTER_DATA"
export const BEGIN_FETCH_ANALYTICS_FILTERS = "BEGIN_FETCH_ANALYTICS_FILTERS"
export const END_FETCH_ANALYTICS_FILTERS = "END_FETCH_ANALYTICS_FILTERS"


//Simulation
export const SIMULATE_WITH_DATA = "SIMULATE_WITH_DATA";
export const SET_SIMULATE_WITH_DATA = "SET_SIMULATE_WITH_DATA";
export const GET_MATERIAL_GROUP_SETTING = "GET_MATERIAL_GROUP_SETTING";
export const GET_COMPLEMENTARY_INFO = 'GET_COMPLEMENTARY_INFO';
export const BEGIN_SIMULATE_FETCH = "BEGIN_SIMULATE_FETCH"
export const FINISHED_SIMULATE_FETCH = "FINISHED_SIMULATE_FETCH" 
export const SET_SIMUALTION_MATERIAL_DATA = "SET_SIMUALTION_MATERIAL_DATA"
export const SET_SIMULATION_QUANTITY_MODIFIER_ARRAY = "SET_SIMULATION_QUANTITY_MODIFIER_ARRAY"
export const SET_SIMULATION_MATERIAL_GROUP_SETTINGS = "SET_SIMULATION_MATERIAL_GROUP_SETTINGS"
export const SET_SIMULATION_GREY_ZONE_ARRAY = "SET_SIMULATION_GREY_ZONE_ARRAY"
export const SET_ADU_FREQ_UPD = 'SET_ADU_FREQ_UPD'
export const SET_ORD_DAYS = 'SET_ORD_DAYS'
export const PREVIOUS_SIM_DATA = 'PREVIOUS_SIM_DATA'
export const GET_SAVED_SIMULATION_DATA = 'GET_SAVED_SIMULATIONS_DATA';
export const SAVE_SIMULATION_DATA = 'SAVE_SIMULATION_DATA';
export const LOAD_SIMULATION_DATA = 'LOAD_SIMULATION_DATA';
export const DELETE_SIMULATION_DATA = 'DELETE_SIMULATION_DATA';
export const BEGIN_SIMULATION_ACTION = 'BEGIN_SIMULATION_ACTION';
export const FINISHED_SIMULATION_ACTION = 'FINISHED_SIMULATION_ACTION';
export const EDIT_SIMULATION_DATA = 'EDIT_SIMULATION_DATA';
export const DELETE_QUANTITY_MODIFIER = 'DELETE_QUANTITY_MODIFIER';
export const MODIFY_GROUP_SETTING = 'MODIFY_GROUP_SETTING';
export const SET_SIMULATION_DATE_RANGE = "SET_SIMULATION_DATE_RANGE";
export const SET_SIMULATION_MODIFY_STATUS = "SET_SIMULATION_MODIFY_STATUS";
export const SET_LOAD_SIMULATION_DATA = "SET_LOAD_SIMULATION_DATA"
export const PREVIOUS_LOADED_SIM_INPUT_DATA = "PREVIOUS_LOADED_SIM_INPUT_DATA"
export const SET_LOADED_SIMULATION_DATA = "SET_LOADED_SIMULATION_DATA" 
export const SET_LOADED_SIMULATION_DATA_STATUS = "SET_LOADED_SIMULATION_DATA_STATUS"
export const SET_SAVED_SIMULATION_INPUTS = "SET_SAVED_SIMULATION_INPUTS" 
export const GET_SIMULATION_STATUS = "GET_SIMULATION_STATUS" 

// Dynamic fields
export const BEGIN_SINGLE_CALCULATION = "BEGIN_SINGLE_CALCULATION";
export const END_SINGLE_CALCULATION = "END_SINGLE_CALCULATION";
export const FAILED_SINGLE_CALCULATION = "FAILED_SINGLE_CALCUATION";
export const BEGIN_SINGLE_CALCULATION_FOR_TABLE_CELL = "BEGIN_SINGLE_CALCULATION_FOR_TABLE_CELL";
export const END_SINGLE_CALCULATION_FOR_TABLE_CELL = "END_SINGLE_CALCULATION_FOR_TABLE_CELL";
export const FAILED_SINGLE_CALCULATION_FOR_TABLE_CELL = "FAILED_SINGLE_CALCUATION_FOR_TABLE_CELL";
export const CLEAR_SINGLE_CALCULATION = "END_SINGLE_CALCULATION";
export const BEGIN_LOAD_DYNAMIC_FIELDS_METADATA = "BEGIN_LOAD_DYNAMIC_FIELDS_METADATA";
export const END_LOAD_DYNAMIC_FIELDS_METADATA = "END_LOAD_DYNAMIC_FIELDS_METADATA";
export const FAILED_LOAD_DYNAMIC_FIELDS_METADATA = "FAILED_LOAD_DYNAMIC_FIELDS_METADATA";
export const BEGIN_DYNAMIC_FIELDS_REQUEST = "BEGIN_DYNAMIC_FIELDS_REQUEST";
export const FAILED_DYNAMIC_FIELDS_REQUEST = "FAILED_DYNAMIC_FIELDS_REQUEST";
export const GET_DYNAMIC_FIELDS = "GET_DYNAMIC_FIELDS";
export const BEGIN_GET_DYNAMIC_FIELDS = "BEGIN_GET_DYNAMIC_FIELDS";
export const FAILED_GET_DYNAMIC_FIELDS = "FAILED_GET_DYNAMIC_FIELDS";
export const RESET_DRAFT_DYNAMIC_FIELD = "RESET_DRAFT_DYNAMIC_FIELD";
export const SET_DRAFT_DYNAMIC_FIELD = "SET_DRAFT_DYNAMIC_FIELD";
export const UPDATE_DRAFT_DYNAMIC_FIELD = "UPDATE_DRAFT_DYNAMIC_FIELD";
export const SAVE_DYNAMIC_FIELD = "SAVE_DYNAMIC_FIELD";
export const EDIT_DYNAMIC_FIELD = "EDIT_DYNAMIC_FIELD";
export const DELETE_DYNAMIC_FIELD = "DELETE_DYNAMIC_FIELD";
