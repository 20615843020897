import React from "react";

import axiosWrapper from "../utils/axiosWrapper";

import _ from "lodash";

import { get, set } from "idb-keyval";

import { toast } from "react-toastify";

import ToastI18nWrapper from "../components/ToastI18nWrapper/ToastI18nWrapper";

import { pusherEvents } from "../utils/constants";
import { getItems, showSyncingSpinner, clearOrderTable } from "./items";
import {
  getUploadHistory,
  getFileTypeLastUpdated,
  updateUploadHistory,
} from "./files";
import { getEvents } from "./calendar";
import { getGroups } from "./groupSettings";
import { getDataset } from "./datasets";
import { getAdvancedSettings } from "./adminConsole";

import { getFirstVisibleWorkspace } from "../utils/mappers/user";

import { setSelectedRow } from "./ordersTable";

import { mapOrderTableRecordToTableRow } from "../utils/mappers/ordersTable";

import {
  GET_WORKSPACES,
  SELECT_WORKSPACE,
  SET_SELECTOR_DISABLED,
  EDIT_ACTUAL_ORDER,
  RESET_ORDER_CALCULATIONS_METADATA,
  UPDATE_WORKSPACE_SETTING,
} from "./types";
import { loadUser } from "./auth";
import { getWorkspaceTimezone } from "../utils/mappers/workspace";

export const getWorkspaces = () => async (dispatch) => {
  try {
    const response = await axiosWrapper.get("/workspaces");
    dispatch({ type: GET_WORKSPACES, payload: response.data });
  } catch (error) {
    if (!_.isNil(error.response) && error.response.status === 401) {
      return;
    }
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });
  }
};

export const resetCurrentWorkspace = () => async (dispatch, getState) => {
  const { workspace, auth } = getState();

  if (_.isNil(auth.user) || _.isEmpty(workspace.workspaces)) {
    dispatch(setCurrentWorkspace(null));
    return;
  }

  const visibleWorkspaces = Object.keys(auth.user.roles).map((workspaceId) =>
    Number(workspaceId)
  );

  const lastSelected = await get("paqtana_last_selected_workspace").catch(
    _.noop
  );

  const currentWorkspaceExists = _.includes(workspace.workspaces.map(ws => ws.id), lastSelected)

  if (
    !_.isNil(lastSelected) &&
    (auth.user.isAdmin || _.includes(visibleWorkspaces, lastSelected))
    && currentWorkspaceExists
  ) {
    dispatch(setCurrentWorkspace(lastSelected));
    return;
  }

  if (_.includes(visibleWorkspaces, getState().workspace.currentWorkspace)) {
    return;
  }

  const first = getFirstVisibleWorkspace(auth.user, workspace.workspaces);

  dispatch(setCurrentWorkspace(first));
};

export const setCurrentWorkspace = (workspace) => async (dispatch) => {
  // This will also clear most of the state, keeping only auth reducer (see root reducer in ./index.js)
  dispatch({ type: SELECT_WORKSPACE, payload: workspace });

  if (!_.isNil(workspace)) {
    await set("paqtana_last_selected_workspace", workspace).catch(_.noop);
  }
};


export const getCurrentWorkspaceData = (workspace) => async (dispatch) => {

  if (!_.isNil(workspace)) {
    dispatch({
      type: RESET_ORDER_CALCULATIONS_METADATA,
    });
    // already getting the workspace data from another place at startup
    // TODO: make sure this is acceptable long term
    // dispatch(getWorkspaces());
    dispatch(getDataset(workspace));
    dispatch(getItems(workspace));
    dispatch(getEvents(workspace));
    dispatch(getGroups(workspace));
    dispatch(getUploadHistory(workspace));
    dispatch(getFileTypeLastUpdated(workspace));
    dispatch(getAdvancedSettings())
  }
};


export const setWorkspaceSelectorDisabled = (value) => async (dispatch) => {
  dispatch({ type: SET_SELECTOR_DISABLED, payload: value });
};

export const subscribeToOrganizationChannel = (
  pusherInstance,
  currentOrganizationId
) => (dispatch, getState) => {
  if (_.isNil(currentOrganizationId)) {
    return _.noop;
  }
  const channel = pusherInstance.subscribe(
    `organization-${currentOrganizationId}`
  );
  const unsubscribe = () =>
    pusherInstance.unsubscribe(`organization-${currentOrganizationId}`);

  channel.bind(pusherEvents["organization_setting_change"], (data) => {
    const { type } = data;
    switch (type) {
      case "permissions":
        dispatch(loadUser());
        break;
      case "calendars":
        dispatch(getEvents(getState().workspace.currentWorkspace));
        break;
      default:
        break;
    }
  });

  channel.bind(pusherEvents["workspaces_change"], (data) => {
    const { updated_workspace } = data;
    dispatch({
      type: UPDATE_WORKSPACE_SETTING,
      payload: updated_workspace,
    });
    dispatch(getCurrentWorkspaceData(getState().workspace.currentWorkspace))
  });

  return unsubscribe;
};

export const subscribeToWorkspaceChannel = (
  pusherInstance,
  currentWorkspaceId
) => (dispatch, getState) => {
  if (_.isNil(currentWorkspaceId)) {
    return _.noop;
  }

  const channel = pusherInstance.subscribe(`workspace-${currentWorkspaceId}`);
  const unsubscribe = () =>
    pusherInstance.unsubscribe(`workspace-${currentWorkspaceId}`);

  channel.bind(pusherEvents["file_parse_success"], (data) => {
    const { section, data_import_instance } = data;

    dispatch(setSelectedRow(null));
    if (section === 'master') {
      dispatch(clearOrderTable());
    }

    dispatch(updateUploadHistory(data_import_instance));
    dispatch(getFileTypeLastUpdated(currentWorkspaceId));
    dispatch(getDataset(currentWorkspaceId));
  });
  channel.bind(pusherEvents["subscription_succeeded"], () => {
    dispatch(getCurrentWorkspaceData(currentWorkspaceId))
  })
  channel.bind(pusherEvents["file_parse_error"], (data) => {
    const { data_import_instance } = data;
    dispatch(updateUploadHistory(data_import_instance));
    dispatch(getFileTypeLastUpdated(currentWorkspaceId));
  });
  channel.bind(pusherEvents["syncing_started"], () => {
    dispatch(showSyncingSpinner(true));
  });
  channel.bind(pusherEvents["syncing_interrupted"], (data) => {
    const { status } = data;
    dispatch(showSyncingSpinner(false, status !== 412));
    // dispatch(showSyncingSpinner(false, status !== 204));
  });
  channel.bind(pusherEvents["new_calculation"], () => {
    dispatch(getItems(currentWorkspaceId, true));
  });
  channel.bind(pusherEvents["group_settings_change"], () => {
    dispatch(getGroups(currentWorkspaceId, true));
  });
  channel.bind(pusherEvents["calendar_events_change"], () => {
    dispatch(getEvents(currentWorkspaceId, true));
  });
  channel.bind(pusherEvents["order_table_change"], () => {
    dispatch(getItems(currentWorkspaceId, true));
  });
  channel.bind(pusherEvents["order_table_row_change"], (data) => {
    const { diff } = data;

    const workspaceTimezone = getWorkspaceTimezone(getState())

    dispatch({
      type: EDIT_ACTUAL_ORDER,
      payload: mapOrderTableRecordToTableRow(diff, workspaceTimezone),
    });
  });

  return unsubscribe;
};

export const setBatchInventoryMode = (value) => async (dispatch, getState) => {
  const body = JSON.stringify({
    current_workspace_id: getState().workspace.currentWorkspace,
    batch_inventory_mode: value,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await axiosWrapper.put(
      "/workspace-settings",
      body,
      config
    );
    dispatch({
      type: UPDATE_WORKSPACE_SETTING,
      payload: response.data,
    });
  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });
  }
};
