import React, { useState, useEffect } from 'react'
import { Grid, Segment, Form, Button, Input } from 'semantic-ui-react'
import CustomCalendarInput from '../../../CustomCalendarInput/CustomCalendarInput'
import { connect } from 'react-redux'
import { changeSimulationQuantityModifier, changeLoadSimulationData } from '../../../../actions/analytics-v2'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { maskTextToExtractFloat } from '../../../../utils/format'
import "./QuantityModifier.css"
import useCurrentDateRange from '../../../../hooks/useCurrentDateRange'
import classNames from 'classnames'
import _ from "lodash"
import { allowAddQuantityModifier } from './validationForm'

const QuantityModifier = 
({ 
    modalData = {},
    closeModal,
    changeSimulationQuantityModifier, 
    simulationQuantityModifiers, 
    dateRange, 
    disabled,
    loadSimulationData,
    changeLoadSimulationData,
})=>{
    const { t } = useTranslation()
    //TODO: improve this hook, because in future could be a problem
    const [ action, setAction ] = useState(false)
    const [ dateAndMultiplier, setDateAndMultiplier ] = useState({  start_date: "", 
                                                                    end_date: "",  
                                                                    multiplier: ""})
    const [ errorFields, setErrorFields] = useState({   startDate: false, 
                                                        endDate: false, 
                                                        multiplier: false})

    const { currentDateRange, isStartDateSelected } = useCurrentDateRange(dateAndMultiplier.start_date, dateRange)
    const [ isModalData, setIsModalData ] = useState(false)
    const { loaded_results="", sim_inputs="" } = loadSimulationData[0] || {}

    useEffect(()=>{
        if("multiplier" in modalData){
            const newModelData = {  
                    start_date: modalData.start_date, 
                    end_date: modalData.end_date,  
                    multiplier: modalData.multiplier
            }
            setDateAndMultiplier(newModelData)
            setIsModalData(true)
        }
    }, [modalData])

    const startDatePlaceholder = "start_date" in modalData 
                                        ? dateAndMultiplier.start_date.replaceAll("-", "/") : "";
    const endDatePlaceholder = "end_date" in modalData 
                                        ? dateAndMultiplier.end_date.replaceAll("-", "/") : "";

                                        
    const addQuantityModifier = ()=>{
        const allow = allowAddQuantityModifier(dateAndMultiplier, setErrorFields, setDateAndMultiplier, setAction, action)
        if(!modalData["multiplier"] && allow && !loadSimulationData.length){
            changeSimulationQuantityModifier([...simulationQuantityModifiers, dateAndMultiplier])
            return 
        }
        if(allow && loadSimulationData.length){
            changeLoadSimulationData([{ loaded_results,
                                        sim_inputs:{...sim_inputs, 
                                                    sim_dataset: [...sim_inputs.sim_dataset, dateAndMultiplier]}}])
        }
    }

    const filterData = (typeArray)=>{
        return typeArray
                        .map((simQtyObj)=>
                                            _.isEqual(simQtyObj, modalData)
                                                ? dateAndMultiplier
                                                : simQtyObj
                                        )
    }
    

    const changeQuantityModifierValue = ()=>{
        const allow = allowAddQuantityModifier(dateAndMultiplier, setErrorFields, setDateAndMultiplier, setAction, action)
        
        if(modalData["multiplier"] && allow && simulationQuantityModifiers.length){
            const newSimulationQuantityModifiers = filterData(simulationQuantityModifiers)
            changeSimulationQuantityModifier(newSimulationQuantityModifiers)
            closeModal()
        }
        if(modalData["multiplier"] && allow && loadSimulationData.length){
            const simulationQuantityModifiers = sim_inputs.sim_dataset
            const newQuantityModifierArray = filterData(simulationQuantityModifiers)
            changeLoadSimulationData([{ loaded_results, 
                                        sim_inputs: {...sim_inputs, sim_dataset: newQuantityModifierArray} }])
            closeModal()
        }
    }

    const inputMultiplierValue = dateAndMultiplier.multiplier ? maskTextToExtractFloat(String(dateAndMultiplier.multiplier)) : ""

    return(
    <Grid.Column mobile={8} widescreen={11} tablet={11}>
        <Segment  disabled={disabled} basic={isModalData}>
            {
                !isModalData    &&  <Grid.Row>
                                        <label 
                                            className="segment-title">
                                            {t("simulation.quantityModifiers")}
                                        </label>
                                    </Grid.Row>
                
            }
            <Grid columns={3} stackable className={classNames({'grid-quantity-modifier': isModalData})}>
            <Grid.Column>
                <CustomCalendarInput 
                    title={t("simulation.startDate")} 
                    required={true} 
                    validRange={dateRange} 
                    error={errorFields.startDate}
                    onDateClick={(date)=>{setDateAndMultiplier({...dateAndMultiplier, start_date: date});}}
                    action={action}
                    disabled={disabled}
                    currentDate={dateRange.startDateRange}
                    placeholder={startDatePlaceholder}
                    />
            </Grid.Column>
            <Grid.Column>
                <CustomCalendarInput 
                    title={t("simulation.endDate")}  
                    required={true} 
                    validRange={currentDateRange} 
                    error={errorFields.endDate}
                    onDateClick={(date)=>setDateAndMultiplier({...dateAndMultiplier, end_date: date})}
                    action={action}
                    disabled={disabled || isStartDateSelected}
                    currentDate={dateRange.startDateRange}
                    placeholder={endDatePlaceholder}
                    />
            </Grid.Column>
            <Grid.Column>
                <Form.Field error={errorFields.multiplier} required disabled={disabled}>
                    <label 
                        className="field-title">
                            {t("simulation.quantityMultiplier")}
                    </label>
                    <Input
                        disabled={disabled}
                        className="multiplier-label" 
                        value={inputMultiplierValue}
                        placeholder={!dateAndMultiplier.multiplier ? "0.009" : null}
                        fluid
                        onChange={(event, valueInserted)=>{setDateAndMultiplier({...dateAndMultiplier, 
                                                            multiplier: valueInserted.value})}}
                        error={errorFields.multiplier}
                        />
                    {
                       isModalData === false
                            ?   <Button 
                                    fluid 
                                    className="button-add"
                                    disabled={disabled}
                                    onClick={(event)=>{ event.preventDefault(); addQuantityModifier()}}
                                    >
                                    {t("simulation.addTittle")}
                                </Button> 
                            : null
                    }
                </Form.Field>
            </Grid.Column>
            </Grid>
            {
                isModalData     &&  <div className={classNames({'action-container': isModalData})}>
                                        <Grid>
                                            <Grid.Row className='action-quantity-modifier-section'>
                                                <Button 
                                                    positive
                                                    onClick={(event)=>{event.preventDefault(); changeQuantityModifierValue();}}>
                                                    {t("confirm")}
                                                </Button>
                                                
                                                <Button 
                                                    color='black'
                                                    onClick={()=>closeModal()}
                                                    >
                                                    {t("cancel")}
                                                </Button>
                                            </Grid.Row>
                                        </Grid> 
                                    </div>
            }
        </Segment>
    </Grid.Column>
    )
}

QuantityModifier.propTypes = {
    modalData: PropTypes.object,
    closeModal: PropTypes.func,
    changeSimulationQuantityModifier: PropTypes.func,
    simulationQuantityModifiers: PropTypes.array,
    dateRange: PropTypes.object,
    disabled: PropTypes.bool,
    loadSimulationData: PropTypes.array,
    changeLoadSimulationData: PropTypes.func,
}

const mapStateToProps = (state)=>({
    simulationQuantityModifiers: state.analytics.simulationQuantityModifiers,
    loadSimulationData: state.analytics.loadSimulationData,
})

export default connect( mapStateToProps,
                        {   changeSimulationQuantityModifier, 
                            changeLoadSimulationData, 
                             })(QuantityModifier)