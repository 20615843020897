import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Grid, Dropdown, DropdownDivider } from "semantic-ui-react";
import "./FormulaInput.css"

const FormulaInput = ({
    onValueChange,
    value,
    
    rowIds,
    allFieldNames,
    additionalFieldNames,
    dynamicFieldNames,
}) => {
    const inputFieldRef = useRef(null);
    const { t } = useTranslation();
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const [filteredAutocompleteOptions, setFilteredAutocompleteOptions] = useState([]);
    const [currentFilterText, setCurrentFilterText] = useState('');
    const [inputValue, setInputValue] = useState(value);
    const [isCheckingAutocompleteSelection, setIsCheckingAutocompleteSelection] = useState(false);
    const [currentFilteredOptionIndex, setCurrentFilteredOptionIndex] = useState(-1);
    const [shouldUseBackticks, setShouldUseBackticks] = useState(false);

    const triggerTokens = ['.', '(', ',', '+', '-', '/', '*', '='];
    const nonColumnTriggerTokens = triggerTokens.filter(trigger => trigger !== '.' && trigger !== '=');
    const supportedOpsAfterTriggerToken = [
        { text: 'avg', repl: 'AVG(', hint: 'AVG(number1, number2, ...)' },
        { text: 'count', repl: 'COUNT(', hint: 'COUNT(value1, [value2], ...)' },
        { text: 'if', repl: 'IF(', hint: 'IF(condition, value_if_true, value_if_false)' },
        { text: 'max', repl: 'MAX(', hint: 'MAX(number1, [number2], ...)' },
        { text: 'min', repl: 'MIN(', hint: 'MIN(number1, [number2], ...)' },
        { text: 'sum', repl: 'SUM(', hint: 'SUM(number1, [number2], ...)' },
        { text: 'round', repl: 'ROUND(', hint: 'ROUND(number, num_digits)'},
        { text: 'roundup', repl: 'ROUNDUP(', hint: 'ROUNDUP(number, num_digits)'},
        { text: 'rounddown', repl: 'ROUNDDOWN(', hint: 'ROUNDDOWN(number, num_digits)'},
    ];

    const checkInputValue = useCallback((currentValue) => {
        const buildAutocompleteOption = (text, repl, backticksRepl, hint, type = null) => {
            return {
                text, repl, backticksRepl, hint: hint, type: type
            };
        };
    
        const buildItemPrefixAutocompleteOption = () => {
            return buildAutocompleteOption('item.', 'ITEM.', '`ITEM`.');
        }
    
        const buildAutocompleteOptionsFromRowIds = () => {
            return rowIds.map(rowId => {
                const id = rowId.replace(/\s/g, '_');
                return buildAutocompleteOption(id, `${id}`, `\`${id}\`.`);
            });
        }
    
        const buildAutocompleteOptionsFromFieldNames = () => {
            return allFieldNames.map(fieldName => {
                const isDynamicField = dynamicFieldNames.includes(fieldName);
                const isAdditionalField = additionalFieldNames.includes(fieldName);
                let fieldType = t("ordersTable.dynamicFields.standard");
                if (isAdditionalField) {
                    fieldType = t("ordersTable.dynamicFields.additional");
                } else if (isDynamicField) {
                    fieldType = t("ordersTable.dynamicFields.dynamic");
                }
                return buildAutocompleteOption(fieldName, fieldName.toLowerCase(), '`' + fieldName.toLowerCase() + '`', null, fieldType);
            });
        };
    
        const getLastTriggerTokenIdx = (str) => {
            // check the string value from right to left to find the last used trigger
            let lastTriggerTokenIdx = -1;
            for (let i = str.length - 1; i >= 0; i--) {
                if (triggerTokens.includes(str[i])) {
                    lastTriggerTokenIdx = i;
                    break;
                }
            }
    
            return lastTriggerTokenIdx;
        };

        // when the spacebar is pressed after a comma, and we still have autocomplete options
        if (currentValue.length > 2 && currentValue.trim().endsWith(',') && autocompleteOptions.length > 0) {
            setCurrentFilteredOptionIndex(-1);
            setFilteredAutocompleteOptions(autocompleteOptions);
            return true;
        }

        // check the last character
        const lastChar = currentValue.trim().length > 0 ? currentValue.trim().substring(currentValue.length - 1) : '';
        if (lastChar === '.') {
            const options = buildAutocompleteOptionsFromFieldNames();
            setAutocompleteOptions(options);

            setCurrentFilteredOptionIndex(-1);
            setFilteredAutocompleteOptions(options);
            return true;
        }

        // we use the second condition to avoid displaying suggestions
        // if it's not an equal to sign at the beginning of the formula input
        if ((lastChar === '=' && currentValue.trim().length === 1)
            || (nonColumnTriggerTokens.includes(lastChar) && currentValue.trim().length > 1)) {
            let options = [...supportedOpsAfterTriggerToken];
            options.push({text: '-'}); // divider
            options.push(buildItemPrefixAutocompleteOption());
            options = [...options, ...buildAutocompleteOptionsFromRowIds()];
            
            setAutocompleteOptions(options);
            
            setCurrentFilteredOptionIndex(-1);
            setFilteredAutocompleteOptions(options);
            return true;
        }

        if (autocompleteOptions.length > 0) {
            const lastTriggerTokenIdx = getLastTriggerTokenIdx(currentValue.trim());
            if (lastTriggerTokenIdx > -1) {
                const filterText = currentValue.substring(lastTriggerTokenIdx + 1).trim();
                setCurrentFilteredOptionIndex(-1);
                setCurrentFilterText(filterText);
                setFilteredAutocompleteOptions(autocompleteOptions.filter(
                    option => option.text.toLowerCase().startsWith(filterText.toLowerCase())));

                return true;
            }

            setCurrentFilteredOptionIndex(-1);
            setFilteredAutocompleteOptions(autocompleteOptions);
        }

        return false;
    }, [
        additionalFieldNames,
        allFieldNames,
        dynamicFieldNames,
        rowIds,
        autocompleteOptions,
        nonColumnTriggerTokens,
        supportedOpsAfterTriggerToken,
        triggerTokens,
        t
    ]);

    const handleInputChange = (e) => {
        // token triggers
        // =, (, comma or math ops (+, -, /, *): show supportedTokensAfterEqual, ITEM. and row IDs
        // .: show json column names
        const currentValue = e.target.value;
        setInputValue(currentValue);
        
        const handled = checkInputValue(currentValue);
        if (!handled) {
            setCurrentFilterText('');
            setFilteredAutocompleteOptions([]);
            setAutocompleteOptions([]);
        }
    };

    const handleAutocompleteOptionClick = (e, data) => {
        const selectedValue = data.value;
        const lastInputValueChar = inputValue.length > 0 ? inputValue.trim().substring(inputValue.trim().length - 1) : '';
        let newInputValue = inputValue;
        if (triggerTokens.indexOf(lastInputValueChar) > -1) {            
            newInputValue = inputValue + selectedValue; 
        } else if (currentFilterText.trim().length > 0) {
            const replacement = currentFilterText.trim();
            const pattern = `${replacement}(?!.*${replacement})`
            newInputValue = inputValue.replace(new RegExp(pattern, 'gi'), selectedValue);
        }

        if (newInputValue.trim().length === 0) {
            // append by default if previous conditions are not true
            newInputValue = inputValue + selectedValue;
        }

        setCurrentFilterText('');
        setCurrentFilteredOptionIndex(-1);
        setFilteredAutocompleteOptions([]);
        setAutocompleteOptions([]);

        setIsCheckingAutocompleteSelection(true);
        setInputValue(newInputValue);
        if (onValueChange) {
            onValueChange(newInputValue);
        }

        if (inputFieldRef.current) {
            inputFieldRef.current.focus();
        }
    };
    
    const autocompleteItem = (option, index) => {
        if (option.text === '-') {
            return (
                <DropdownDivider key={option.text} />
            );
        }

        return (
            <Dropdown.Item
                className={"formula-autocomplete-item"}
                key={option.text}
                onClick={handleAutocompleteOptionClick}
                value={shouldUseBackticks ? option.backticksRepl : option.repl}
                selected={index === currentFilteredOptionIndex}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={option.hint ? 4 : 11}>
                            {option.type ? option.text : option.text.toUpperCase()}
                        </Grid.Column>
                        {!_.isEmpty(option.hint) &&
                        <Grid.Column width={7}>
                            <span className="option-hint">{option.hint}</span>
                        </Grid.Column>
                        }
                        <Grid.Column width={5} textAlign="right">
                            <span className="option-type">{option.type || ''}</span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Dropdown.Item>
        );
    }

    useEffect(() => {
        if (value === inputValue) {
            return;
        }
        setInputValue(value);
    }, [value, inputValue, setInputValue]);

    useEffect(() => {
        const specialCharsRegex = /[^\w\s]*/gm
        let hasSpecialChars = false;
        rowIds.forEach(rowId => {
            hasSpecialChars = rowId.match(specialCharsRegex).filter(y => y.trim().length > 0).length > 0;
        });
        allFieldNames.forEach(fieldName => {
            hasSpecialChars = fieldName.match(specialCharsRegex).filter(y => y.trim().length > 0).length > 0;
        });
        setShouldUseBackticks(hasSpecialChars);
    }, [rowIds, allFieldNames, setShouldUseBackticks])

    useEffect(() => {
        if (!isCheckingAutocompleteSelection) {
            return;
        }
        checkInputValue(inputValue);
        setIsCheckingAutocompleteSelection(false);
    }, [checkInputValue, inputValue, isCheckingAutocompleteSelection, setIsCheckingAutocompleteSelection]);

    useEffect(() => {
        if (filteredAutocompleteOptions.length > 0) {
            setCurrentFilteredOptionIndex(0);
        }
    }, [filteredAutocompleteOptions, setCurrentFilteredOptionIndex]);

    useEffect(() => {
        // use JavaScript since we can't use the ref prop in the current version of Semantic UI
        if (currentFilteredOptionIndex > -1) {
            const menu = document.getElementById("formulaSuggestionsMenu");
            const selectedItem = menu.querySelector('.formula-autocomplete-item.selected');
            if (selectedItem && (currentFilteredOptionIndex < 4 || selectedItem.offsetTop > menu.offsetHeight)) {
                // we check if the index is less than 4 because only 4 items are displayed at a time
                // TODO: use an alternative approach to check that the menu item is not within the
                // visible scroll viewport before scrolling
                menu.scrollTop = selectedItem.offsetTop;
            }
        }
    }, [currentFilteredOptionIndex]);

    return (
        <>
            <Form.Field
                className="formula-input"
                required>
                <label>{t("ordersTable.dynamicFields.formula")}</label>
                <input
                    id="formulaInput"
                    autoComplete="off"
                    required
                    name="formula"
                    onChange={(e) => {
                        handleInputChange(e);
                        if (onValueChange) {
                            onValueChange(e.target.value);
                        }
                    }}
                    ref={inputFieldRef}
                    value={inputValue}
                    onKeyUp={(e) => {
                        if (filteredAutocompleteOptions.length > 0) {
                            if (e.keyCode === 32) {
                                // spacebar handling for open semantic UI dropdown
                                setFilteredAutocompleteOptions([]);
                                setInputValue(`${inputValue} `);
                                return;
                            }

                            if (e.keyCode === 38) {
                                // up arrow
                                if (currentFilteredOptionIndex > -1) {
                                    setCurrentFilteredOptionIndex(currentFilteredOptionIndex - 1);
                                }
                                return;
                            }
                            if (e.keyCode === 40) {
                                // down arrow
                                if (currentFilteredOptionIndex < filteredAutocompleteOptions.length - 1) {
                                    setCurrentFilteredOptionIndex(currentFilteredOptionIndex + 1);
                                }
                                return;
                            }

                            if (e.keyCode === 13 && currentFilteredOptionIndex > -1) {
                                // Enter key
                                handleAutocompleteOptionClick(null,
                                    { value: shouldUseBackticks ?
                                        filteredAutocompleteOptions[currentFilteredOptionIndex].backticksRepl :
                                        filteredAutocompleteOptions[currentFilteredOptionIndex].repl }
                                );
                            }
                        }
                    }}
                    />
            </Form.Field>
            <div className="formula-dropdown-container">
                <Dropdown
                    className="formula-autocomplete-list"
                    fluid
                    floating
                    open={filteredAutocompleteOptions.length > 0}
                    >
                    <Dropdown.Menu id="formulaSuggestionsMenu" className="formula-dropdown-menu">
                        {filteredAutocompleteOptions.map((option, index) => (autocompleteItem(option, index)))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
}

FormulaInput.propTypes = {
    onValueChange: PropTypes.func,
    value: PropTypes.string,

    rowIds: PropTypes.array,
    allFieldNames: PropTypes.array,
    additionalFieldNames: PropTypes.array,
    dynamicFieldNames: PropTypes.array,
};

FormulaInput.defaultProps = {
    onValueChange: null,
    value: '',

    rowIds: [],
    allFieldNames: [],
    additionalFieldNames: [],
    dynamicFieldNames: [],
};

const mapStateToProps = (state) => {
    return {
        rowIds: state.items.itemIds,
        allFieldNames: state.dynamicFields.allFieldNames,
        additionalFieldNames: state.dynamicFields.additionalFieldNames,
        dynamicFieldNames: state.dynamicFields.dynamicFieldNames,
    }
};

export default connect(mapStateToProps, {})(FormulaInput);
