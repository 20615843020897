import React, { useMemo, useState, useEffect } from "react";
import usePrevious from "../../../hooks/usePrevious";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import { Button, Grid, Segment, Loader, Dropdown, Divider, Icon } from "semantic-ui-react";
import { getSafeSelectedWorkspace } from "../../../utils/mappers/user";
import { queryReport, setFieldFilterProperties, setSelectedFieldFilters } from "../../../actions/analytics-v2"; 
import ToggleSection from "./ToggleSection/ToggleSection";
import "./AnalyticsSection.css"
import ChartModifier from "./ChartModifier/ChartModifier";
import { WasAggregateSelected } from "../../../utils/constants";
import useAnalyticsChartDataConvertor from "../../../hooks/useAnalyticsChartDataConvertor";
import {
    ANALYTICS_CHART_OPTIONS,
    isCurrentChartOneOrChartTwo,
    getGraphOptionsByChart,
    transformComboChartDataWithLineFix,
    filterChartDataByDateRange
} from "./utils";
import { mappedFiledFiltersWithNewDefaultValues } from "../../../utils/mappers/analytics";

const AnalyticsSection = ( {
    chartOneHeight,
    currentWorkspaceId,
    loadingAnalyticsChartData,
    loadingDataset,
    analyticsToggleOptions,
    analyticsByMaterialChartData,
    analyticsChartData,
    analyticsAllWorkspacesChartData,
    allWorkspacesFieldFilters,
    selectedAllWorkspacesFiedFilter,
    setFieldFilterProperties,
    setSelectedFieldFilters,
    loadingAnalyticsFilters,
}) => {
    const { t } = useTranslation();
    const analyticCharts = {analyticsChartData, analyticsByMaterialChartData, analyticsAllWorkspacesChartData}
    const analyticsChartDataFormatObject = useAnalyticsChartDataConvertor(analyticCharts)
    const fieldFilter = allWorkspacesFieldFilters
    const optionAll = { key: "all", text: t("groupSettings.all"), value: "all" };
    const [currentFilterFieldsData, setCurrentFilterFieldsData] = useState();
    const [selectedWorkspaces, setSelectedWorkspaces] = useState([currentWorkspaceId]);
    const [isFilterSectionCollapsed, setIsFilterSectionCollapsed] = useState(true);
    const [hasExpandableFilterChanges, setHasExpandableFilterChanges] = useState(false);
    
    // Calculate default dates
    const getTodayFormatted = () => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    };
    
    const get12MonthsAgoFormatted = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - 12);
        return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    };
    
    // Set up separate date states for aggregate and single material views
    // Date inputs for aggregate view
    const [aggregateFromDate, setAggregateFromDate] = useState(get12MonthsAgoFormatted());
    const [aggregateToDate, setAggregateToDate] = useState(getTodayFormatted());
    const previousAggregateFromDate = usePrevious(aggregateFromDate);
    const previousAggregateToDate = usePrevious(aggregateToDate);
    
    // Date inputs for single material view
    const [singleMaterialFromDate, setSingleMaterialFromDate] = useState(get12MonthsAgoFormatted());
    const [singleMaterialToDate, setSingleMaterialToDate] = useState(getTodayFormatted());
    
    // Active date filters that have been applied
    const [activeFromDate, setActiveFromDate] = useState(get12MonthsAgoFormatted());
    const [activeToDate, setActiveToDate] = useState(getTodayFormatted());

    const wasAllWorkspacesSelected = true;
    const oldSelectedFieldData = selectedAllWorkspacesFiedFilter;
    const temporalFieldFilters = { ...oldSelectedFieldData, ...currentFilterFieldsData }

    const previousTemporalFieldFilters = usePrevious(temporalFieldFilters);
    const previousWasAllWorkspacesSelected = true;
    const previousSelectedWorkspaces = usePrevious(selectedWorkspaces);

    let currentAnalyticsChartData = { 
        options: ANALYTICS_CHART_OPTIONS,
        series: [], 
    }

    const currentChartData = isCurrentChartOneOrChartTwo(analyticsChartDataFormatObject, analyticsToggleOptions)
    const optionsByChart= getGraphOptionsByChart(analyticCharts, analyticsChartDataFormatObject, analyticsToggleOptions)
    
    // First apply the standard transformation
    let transformedSeries = _.isEmpty(currentChartData) ? [] : transformComboChartDataWithLineFix(currentChartData, analyticsToggleOptions);
    
    // Then apply date filtering for single material view - only with active date filters
    if (!analyticsToggleOptions[WasAggregateSelected] && !_.isEmpty(transformedSeries)) {
        transformedSeries = filterChartDataByDateRange(transformedSeries, activeFromDate, activeToDate);
    }
    
    // Assign the final series to the chart data
    currentAnalyticsChartData['series'] = transformedSeries;
    currentAnalyticsChartData['options'] = optionsByChart

    // Update the active date filters when dates are applied via Apply button
    const applyDateFilters = (fromDate, toDate) => {
        setActiveFromDate(fromDate);
        setActiveToDate(toDate);
    };

    const haveAggregateFiltersChanged = useMemo(() => {
        // Even if the filter section is collapsed, we should still track if any filter values have changed
        const hasFilterValuesChanged = !_.isEqual(previousTemporalFieldFilters, temporalFieldFilters);
        const hasWorkspaceSelectionChanged = !_.isEqual(previousWasAllWorkspacesSelected, wasAllWorkspacesSelected) ||
            !_.isEqual(previousSelectedWorkspaces, selectedWorkspaces);
        const hasDateRangeChanged = !_.isEqual(previousAggregateFromDate, aggregateFromDate) ||
            !_.isEqual(previousAggregateToDate, aggregateToDate);
            
        // If any of these have changed, the filters have changed
        return hasFilterValuesChanged || hasWorkspaceSelectionChanged || hasDateRangeChanged;
    }, [
        previousTemporalFieldFilters,
        previousWasAllWorkspacesSelected,
        temporalFieldFilters,
        wasAllWorkspacesSelected,
        previousSelectedWorkspaces,
        selectedWorkspaces,
        previousAggregateFromDate,
        aggregateFromDate,
        previousAggregateToDate,
        aggregateToDate,
    ]);

    const handleMultipleDropdownChange = (data, field) => {
        const selectedValue = data.value;
        // Create the field data object regardless of whether selectedValue is empty or not
        const currentFieldData = {
            [field.accessor]: { name: field.name, values: selectedValue },
        };
        setCurrentFilterFieldsData(currentFieldData);
        
        // Update the selected field filters that will be sent to the API
        const updatedFilters = {
            ...selectedAllWorkspacesFiedFilter,
            ...currentFieldData
        };
        setSelectedFieldFilters(updatedFilters);
        
        // Make sure filter changes are recognized regardless of whether the section is collapsed
        const mappedFieldFitlers = mappedFiledFiltersWithNewDefaultValues(fieldFilter, field, selectedValue);
        setFieldFilterProperties(mappedFieldFitlers, true);
        
        // Signal that expandable filters have changed - for both adding and removing values
        setHasExpandableFilterChanges(true);
    }

    // Toggle filter section collapse/expand
    const toggleFilterSection = () => {
        // Just toggle the visual state, don't affect the filter values
        setIsFilterSectionCollapsed(!isFilterSectionCollapsed);
    };

    // Initialize active date filters once on component mount
    useEffect(() => {
        if (analyticsToggleOptions[WasAggregateSelected]) {
            setActiveFromDate(aggregateFromDate);
            setActiveToDate(aggregateToDate);
        } else {
            setActiveFromDate(singleMaterialFromDate);
            setActiveToDate(singleMaterialToDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array means this runs once on mount

    return ( 
        <Segment basic>
            <Segment>
                <Grid stackable >
                    <Grid.Row columns={2}>
                        <Grid.Column className="toggle-section-column" stretched widescreen={4} computer={5} tablet={5} mobile={5}>
                            <ToggleSection/>
                        </Grid.Column>
                        <Grid.Column className="chart-modifier-column" stretched widescreen={12} computer={11} tablet={11} mobile={11}>
                            <ChartModifier 
                                selectedWorkspaces={selectedWorkspaces}
                                setSelectedWorkspaces={setSelectedWorkspaces}
                                // Pass the right props based on view mode
                                fromDate={analyticsToggleOptions[WasAggregateSelected] ? aggregateFromDate : singleMaterialFromDate}
                                setFromDate={analyticsToggleOptions[WasAggregateSelected] ? setAggregateFromDate : setSingleMaterialFromDate}
                                toDate={analyticsToggleOptions[WasAggregateSelected] ? aggregateToDate : singleMaterialToDate}
                                setToDate={analyticsToggleOptions[WasAggregateSelected] ? setAggregateToDate : setSingleMaterialToDate}
                                applyDateFilters={applyDateFilters}
                                temporalFieldFilters={temporalFieldFilters}
                                haveFiltersChanged={analyticsToggleOptions[WasAggregateSelected] ? haveAggregateFiltersChanged : true}
                                hasExpandableFilterChanges={hasExpandableFilterChanges}
                                setHasExpandableFilterChanges={setHasExpandableFilterChanges}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {
                        analyticsToggleOptions[WasAggregateSelected] && fieldFilter && 
                        fieldFilter.filter((field) => field.isChecked === true).length > 0 &&
                            (<Grid.Row>
                                <Grid.Column>
                                    <Segment className={`filter-section ${isFilterSectionCollapsed ? 'filter-section-collapsed' : ''}`}>
                                        <div className="filter-section-header" onClick={toggleFilterSection}>
                                            <h4 className="filter-section-title">
                                            {t("groupSettings.filters")}
                                                <span className="filter-count">
                                                    ({fieldFilter.filter((field) => field.isChecked === true).length})
                                                </span>
                                            </h4>
                                            <Button 
                                                icon 
                                                basic 
                                                size="tiny" 
                                                className="toggle-filter-button"
                                            >
                                                <Icon name={isFilterSectionCollapsed ? "angle down" : "angle up"} />
                                            </Button>
                                        </div>
                                        
                                        <div className="filter-section-content" style={{ display: isFilterSectionCollapsed ? 'none' : 'block' }}>
                                            <Grid stackable>
                                                {fieldFilter && fieldFilter
                                                .filter((field) => field.isChecked === true)
                                                .map((field) => (
                                                    <Grid.Column
                                                    stretched
                                                    width={4}
                                                    key={field.accessor}
                                                    >
                                                        <label className="dropdown">{field.name}</label>
                                                        <Dropdown
                                                            className={"multiple-dropdown"}
                                                            value={field.defaultValues}
                                                            options={[...field.options, optionAll]}
                                                            fluid
                                                            multiple
                                                            search
                                                            selection
                                                            onChange={(e, data) => {
                                                                handleMultipleDropdownChange(data, field)
                                                            }}
                                                            closeOnChange={false}
                                                            menuPosition="absolute"
                                                            menuDirection="down"
                                                            upward={false}
                                                            scrolling
                                                        />
                                                    </Grid.Column>
                                                ))}
                                            </Grid>
                                            <Divider inverted />
                                        </div>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            )
                    }
                    <Grid.Row>
                        <Loader active={loadingDataset || loadingAnalyticsChartData || loadingDataset || loadingAnalyticsFilters} size="big"/>
                    </Grid.Row>
                    </Grid>
            </Segment> 
            <Grid.Row>
                <Grid.Column>
                    <Segment>
                        <Chart
                            options={currentAnalyticsChartData.options}
                            series={currentAnalyticsChartData.series}
                            height={chartOneHeight}
                            type="area"
                        />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Segment>
    
    )
}

AnalyticsSection.propTypes = {
    chartOneHeight: PropTypes.number,
    analyticsPass: PropTypes.string,
    currentWorkspaceId: PropTypes.number,
    loadingAnalyticsChartData: PropTypes.bool,
    queryReport: PropTypes.func,
    loadingDataset: PropTypes.bool,
    analyticsToggleOptions: PropTypes.object,
    analyticsByMaterialChartData: PropTypes.object,
    analyticsChartData: PropTypes.object,
    analyticsAllWorkspacesChartData: PropTypes.object,
    currentWorkspaceFieldFilters: PropTypes.array,
    allWorkspacesFieldFilters: PropTypes.array,
    selectedCurrentWorkspaceFieldFilter: PropTypes.object,
    selectedAllWorkspacesFiedFilter: PropTypes.object,
    setFieldFilterProperties: PropTypes.func,
    setSelectedFieldFilters: PropTypes.func,
    loadingAnalyticsFilters: PropTypes.bool,
    workspaces: PropTypes.array,
  };

const mapStateToProps = (state) => ({
    /* pull pieces of the state */
    chartOneHeight: state.analytics.chartOneHeight,
    currentWorkspaceId: getSafeSelectedWorkspace(state),
    loadingAnalyticsChartData: state.analytics.loadingAnalyticsChartData,
    loadingDataset: state.dataset.loading,
    analyticsToggleOptions: state.analytics.analyticsToggleOptions,
    analyticsByMaterialChartData: state.analytics.analyticsByMaterialChartData,
    analyticsChartData: state.analytics.analyticsChartData,
    analyticsAllWorkspacesChartData: state.analytics.analyticsAllWorkspacesChartData,
    currentWorkspaceFieldFilters: state.analytics.currentWorkspaceFieldFilters,
    allWorkspacesFieldFilters: state.analytics.allWorkspacesFieldFilters,
    selectedCurrentWorkspaceFieldFilter: state.analytics.selectedCurrentWorkspaceFieldFilter,
    selectedAllWorkspacesFiedFilter: state.analytics.selectedAllWorkspacesFiedFilter,
    loadingAnalyticsFilters: state.analytics.loadingAnalyticsFilters,
    workspaces: state.workspace.workspaces,
});

export default connect(mapStateToProps, { queryReport, setFieldFilterProperties, setSelectedFieldFilters })( AnalyticsSection );