import React from "react";

import {
  GET_ANALYTICS_PASS,
  SIMULATE_WITH_DATA,
  GET_MATERIAL_GROUP_SETTING,
  GET_COMPLEMENTARY_INFO,
  BEGIN_SIMULATE_FETCH,
  FINISHED_SIMULATE_FETCH,
  SET_SIMUALTION_MATERIAL_DATA,
  SET_SIMULATION_QUANTITY_MODIFIER_ARRAY,
  SET_SIMULATION_MATERIAL_GROUP_SETTINGS,
  SET_SIMULATION_GREY_ZONE_ARRAY,
  SET_ADU_FREQ_UPD,
  SET_ORD_DAYS,
  PREVIOUS_SIM_DATA,
  GET_SAVED_SIMULATION_DATA,
  SAVE_SIMULATION_DATA,
  LOAD_SIMULATION_DATA,
  DELETE_SIMULATION_DATA,
  BEGIN_SIMULATION_ACTION,
  FINISHED_SIMULATION_ACTION,
  DELETE_QUANTITY_MODIFIER,
  MODIFY_GROUP_SETTING,
  SET_SIMULATION_DATE_RANGE,
  SET_SIMULATION_MODIFY_STATUS,
  SET_LOAD_SIMULATION_DATA,
  PREVIOUS_LOADED_SIM_INPUT_DATA,
  SET_LOADED_SIMULATION_DATA,
  SET_LOADED_SIMULATION_DATA_STATUS,
  SET_SIMULATE_WITH_DATA,
  GET_SIMULATION_STATUS,
  ANALYTICS_ALLOWED_STATUS,
  SET_TOGGLE_OPTIONS,
  SET_ANALYTICS_MATERIAL_DATA,
  BEGIN_ANALYZE,
  END_ANALYZE,
  SET_USER_SELECTED_ANALYTICS_TIME_FILTER,
  SET_SINGLE_ITEM_TIME_FILTER,
  SET_ANALYTICS_ALL_WORKSPACES_FIELD_FILTERS_PROPERTIES,
  SET_SELECTED_ALL_WORKSPACES_FIELD_FILTER_DATA,
  GET_ANALYTICS_ALL_WORKSPACES_FIELD_FILTERS,
  BEGIN_FETCH_ANALYTICS_FILTERS,
  END_FETCH_ANALYTICS_FILTERS
} from "../actions/types";
import axiosWrapper from "../utils/axiosWrapper";
import { mapAnalyticsReportV2toV1 } from "../utils/mappers/analytics";
import { toast } from "react-toastify";
import ToastI18nWrapper from "../components/ToastI18nWrapper/ToastI18nWrapper";
import { mapColumnsToGroupFields, mapAnalyticsContentDisableStatus } from "../utils/mappers/analytics";
import _ from "lodash";
import { ANALYTICS_CHARTS } from "../utils/constants";
import { cleanFilters } from "../components/AnalyticsV2/utils";
const now = new Date();
const defaultLatestDatetime = now.toISOString();
const MonthsAgo12 = new Date(now.setMonth(now.getMonth() - 12));
const defaultEarliestDatetime = MonthsAgo12.toISOString();

// Using POST for extensive querying, however it is recognized this is only requesting a resource
export const queryReport = ({
  currentWorkspaceId,
  selectedWorkspaces,
  earliestDatetime = defaultEarliestDatetime,
  latestDatetime = defaultLatestDatetime,
  t,
  fieldFilters = {},
  // Note: timeFilter is just for retrocompatibility of single material process, and we'll hopefully retire that at some point
  // timeFilter = {},
  isFirstDataFetch = false,
  materialId = null,
}) => async (dispatch) => {
  try {
    
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // if single material report (still use analytics v1 for now):
    if (materialId !== null) {
      const body = JSON.stringify({
        current_workspace_id: currentWorkspaceId,
        field_filters: fieldFilters,
        time_filter: {
          system_value: 52,
        },
        material_id: materialId,
        all_workspaces: false
      });
  
      dispatch({
        type: BEGIN_ANALYZE
      })
  
      const response = await axiosWrapper.post("/analytics-report", body, config);
      let responseObject = response.data
      const responseHasErrorMessage = Object.prototype.hasOwnProperty.call(responseObject, "message")
      if(!_.isEmpty(responseObject) && !responseHasErrorMessage){
        const chartType = ANALYTICS_CHARTS["single_material"]
        dispatch({
          type: chartType,
          payload: responseObject["current_workspace"]["single_material"]
        })
      }

    } else {
    // if aggregate report use analytics v2:
      // Apply filter transformations while preserving empty arrays (filter value removals)
      const cleanedFilters = cleanFilters(fieldFilters);

      const body = JSON.stringify({
        workspaces_ids: selectedWorkspaces,
        field_filters: cleanedFilters, // Use the cleaned filters that preserve empty arrays
        earliest_datetime: earliestDatetime,
        latest_datetime: latestDatetime,
      });

      
  
      dispatch({
        type: BEGIN_ANALYZE
      })
  
      const response = await axiosWrapper.post("/analytics-2/v2/analytics-report", body, config);
      let responseObject = response.data
  
      const responseHasErrorMessage = Object.prototype.hasOwnProperty.call(responseObject, "message")
  
      if(!_.isEmpty(responseObject) && !responseHasErrorMessage){
        dispatch({
          type: ANALYTICS_CHARTS["aggregate_materials"],
          payload: mapAnalyticsReportV2toV1(responseObject)
        })
      }
    }
    

    // TODO: review this so that it gets the filters from the workspaces it needs/were selected
    // including looking at not leaving true for allworkspaces, etc...
    if(isFirstDataFetch){
      dispatch(getAnalyticsFilters(currentWorkspaceId, t));
    }
    if(!isFirstDataFetch){
      dispatch(setSelectedFieldFilters(fieldFilters));
    }

    dispatch({
      type: END_ANALYZE
    })

  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      autoClose: 3000,
      toastId: "genericError",
    });
    dispatch({
      type: END_ANALYZE
    })
  }
};

/**
 * @param {number[]} workspaceIds - Array of workspace IDs
 */
export const getAnalyticsFilters = (currentWorkspaceId, t) => async (
  dispatch
) => {
  try {

    dispatch({
      type: BEGIN_FETCH_ANALYTICS_FILTERS
    })

    const params = {'current_workspace_id': currentWorkspaceId, 'all_workspaces':true} 
    
    // TODO: consider removing one or both of below once you don't need them
    const responseFieldFilters = await axiosWrapper.get(`/analytics-filters`, { params })


    const responseFieldFiltersData = responseFieldFilters.data
    const typeOfFieldFilter = GET_ANALYTICS_ALL_WORKSPACES_FIELD_FILTERS
    dispatch({
      type: typeOfFieldFilter,
      payload: mapColumnsToGroupFields(t, responseFieldFiltersData)
    })

    dispatch({
      type: END_FETCH_ANALYTICS_FILTERS
    })

  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      autoClose: 3000,
      toastId: "genericError",
    });
    
    dispatch({
      type: END_FETCH_ANALYTICS_FILTERS
    })
  }
};

export const changeUserSelectedAnalyticsTimeFilter = (newSelectedTimeFilterData)=> async (dispatch) =>{
  dispatch({
    type: SET_USER_SELECTED_ANALYTICS_TIME_FILTER,
    payload: newSelectedTimeFilterData
  })
}

export const changeSingleItemTimeFilter = (newSingleItemTimeFilterData) => async (dispatch) => {
  dispatch({
    type: SET_SINGLE_ITEM_TIME_FILTER,
    payload: newSingleItemTimeFilterData
  })
}

export const setSelectedFieldFilters = (newGroupData) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_ALL_WORKSPACES_FIELD_FILTER_DATA,
    payload: newGroupData,
  });
};


export const setFieldFilterProperties = (markedFields) => async (dispatch) => {
  // Note: forcing to true, because all workspaces should always be true in analytics v2 and prevents changing many components
  const customType = SET_ANALYTICS_ALL_WORKSPACES_FIELD_FILTERS_PROPERTIES
  dispatch({
    type: customType,
    payload: markedFields,
  });
};

export const getAnalyticsPass = (currentWorkspaceId) => async (dispatch) => {

  try {
    const response = await axiosWrapper.get(
      `/analytics-credentials?current_workspace_id=${currentWorkspaceId}`
    );
    dispatch({
      type: GET_ANALYTICS_PASS,
      payload: response.data
    })
  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      autoClose: 3000,
      toastId: "genericError",
    });
  }
}

export const simulateData = (
  current_workspace_id,
  material_id,
  sim_dataset,
  g_zone_input,
  group_info,
  adu_freq,
  ordering_days
) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };


  const body = JSON.stringify({
    current_workspace_id,
    material_id,
    sim_dataset,
    g_zone_input,
    group_info,
    adu_freq,
    ordering_days
  });

  dispatch({
    type: BEGIN_SIMULATE_FETCH
  })

  try {
    const response = await axiosWrapper.post(
      `/analytics/simulations`, body, config
    )
    dispatch({
      type: SIMULATE_WITH_DATA,
      payload: response.data
    })
    dispatch({
      type: PREVIOUS_SIM_DATA,
      payload: {
        current_workspace_id,
        material_id,
        sim_dataset,
        g_zone_input,
        group_info,
        adu_freq,
        ordering_days
      }
    })
    dispatch({
      type: GET_COMPLEMENTARY_INFO,
      payload: response.data.complementary_info
    })
  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      autoClose: 3000,
      toastId: "genericError"
    })
  }

  dispatch({
    type: FINISHED_SIMULATE_FETCH
  })
}

export const getMaterialGroupSetting = (current_workspace_id, material_id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    current_workspace_id, material_id
  });

  try {
    const response = await axiosWrapper.post(
      `/analytics/sim-corollary`, body, config
    )
    dispatch({
      type: GET_MATERIAL_GROUP_SETTING,
      payload: response.data
    })
  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      autoClose: 3000,
      toastId: "genericError"
    })
  }
}

export const changeSimulationMaterialData = ({ name, data }) => async (dispatch) => {
  dispatch({
    type: SET_SIMUALTION_MATERIAL_DATA,
    payload: { name, data }
  })
}

export const changeSimulationQuantityModifier = (quantityModifiersArray) => async (dispatch) => {
  dispatch(
    {
      type: SET_SIMULATION_QUANTITY_MODIFIER_ARRAY,
      payload: quantityModifiersArray
    }
  )
}

export const changeSimulationMaterialGroupSetting = (newMaterialGroupSettings) => async (dispatch) => {
  dispatch({
    type: SET_SIMULATION_MATERIAL_GROUP_SETTINGS,
    payload: newMaterialGroupSettings
  })
}

export const changeSimulationGreyZoneArray = (newGreyZOneArray) => async (dispatch) => {
  dispatch({
    type: SET_SIMULATION_GREY_ZONE_ARRAY,
    payload: newGreyZOneArray
  })
}

export const changeSimAduFreqUpd = (newAduFreqUpd) => async (dispatch) => {
  dispatch({
    type: SET_ADU_FREQ_UPD,
    payload: newAduFreqUpd
  })
}

export const changeSimOrdDays = (newOrdDays) => async (dispatch) => {
  dispatch({
    type: SET_ORD_DAYS,
    payload: newOrdDays
  })
}

export const getAllSavedSimulations = (current_workspace_id) => async (dispatch) => {

  dispatch({
    type: BEGIN_SIMULATE_FETCH
  })

  try {
    const response = await axiosWrapper.get(
      `/analytics/simulations?current_workspace_id=${current_workspace_id}`
    )
    dispatch({
      type: GET_SAVED_SIMULATION_DATA,
      payload: response.data
    })
  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      autoClose: 3000,
      toastId: "genericError"
    })
  }

  dispatch({
    type: FINISHED_SIMULATE_FETCH
  })

}

export const saveSimulationData =
  (current_workspace_id,
    material_id,
    sim_dataset,
    g_zone_input,
    group_info,
    adu_freq,
    ordering_days,
    save_name) => async (dispatch) => {

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        current_workspace_id, material_id, sim_dataset, g_zone_input, group_info, adu_freq, ordering_days, save_name
      });

      dispatch({
        type: BEGIN_SIMULATION_ACTION
      })

      try {
        const response = await axiosWrapper.post(
          `/analytics/simulations`, body, config
        )
        dispatch({
          type: SAVE_SIMULATION_DATA,
          payload: response.data
        })

        toast.success(
          <ToastI18nWrapper translateKey={`simulation.savedData`} customMessage={response.data[0].message} />,
          { timeout: 300 }
        );

      } catch (error) {
        toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
          autoClose: 3000,
          toastId: "genericError"
        })
      }

      dispatch({
        type: FINISHED_SIMULATION_ACTION
      })

    }

export const loadSavedSimulationData = (current_workspace_id, material_id, save_name) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ current_workspace_id, material_id, save_name });

  dispatch({
    type: BEGIN_SIMULATE_FETCH
  })

  try {
    const response = await axiosWrapper.post(
      `/analytics/simulations`, body, config
    )
    dispatch({
      type: LOAD_SIMULATION_DATA,
      payload: response.data
    })

    dispatch({
      type: PREVIOUS_LOADED_SIM_INPUT_DATA,
      payload: response.data[0].sim_inputs
    })
  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      autoClose: 3000,
      toastId: "genericError"
    })
  }

  dispatch({
    type: FINISHED_SIMULATE_FETCH
  })

}

export const deleteSavedSimulationData = (current_workspace_id, sim_id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      current_workspace_id, sim_id
    })
  };

  dispatch({
    type: BEGIN_SIMULATION_ACTION
  })

  try {
    const response = await axiosWrapper.delete(
      `/analytics/simulations`, config
    )
    dispatch({
      type: DELETE_SIMULATION_DATA,
      payload: response.data
    })

    toast.success(
      <ToastI18nWrapper translateKey={`simulation.deletedData`} customMessage={response.data[0].message} />,
      { timeout: 300 }
    );

  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      autoClose: 3000,
      toastId: "genericError"
    })
  }

  dispatch({
    type: FINISHED_SIMULATION_ACTION
  })
}

export const renameSavedSimulation = (current_workspace_id, save_name, sim_id, material_id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    current_workspace_id, save_name, sim_id, material_id
  });

  dispatch({
    type: BEGIN_SIMULATION_ACTION
  })

  try {

    await axiosWrapper.put(
      `/analytics/simulations`, body, config
    )

    toast.success(
      <ToastI18nWrapper translateKey={`simulation.updateName`} customMessage={save_name} />,
      { timeout: 300 }
    );
  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      autoClose: 3000,
      toastId: "genericError"
    })
  }

  dispatch({
    type: FINISHED_SIMULATION_ACTION
  })
}


export const deleteSingleQuantityData = (newQuantityArray) => async (dispatch) => {
  dispatch({
    type: DELETE_QUANTITY_MODIFIER,
    payload: newQuantityArray
  })
}

export const deleteGroupSettingData = (newGroupSettingData) => async (dispatch) => {
  dispatch({
    type: MODIFY_GROUP_SETTING,
    payload: newGroupSettingData
  })
}

export const setSimulationDateRange = (validSimulationRange) => async (dispatch) => {
  dispatch({
    type: SET_SIMULATION_DATE_RANGE,
    payload: validSimulationRange
  })
}

export const changeModifyStatus = (status) => async (dispatch) => {
  dispatch({
    type: SET_SIMULATION_MODIFY_STATUS,
    payload: status,
  })
}

export const changeLoadSimulationData = (newValue) => async (dispatch) => {
  dispatch({
    type: SET_LOAD_SIMULATION_DATA,
    payload: newValue
  })
}

export const changeInputSimulationDataStatus = (status) => async (dispatch) => {
  dispatch({
    type: SET_LOADED_SIMULATION_DATA_STATUS,
    payload: status
  })
}

export const changeLoadSelectedData = (sim_name, material_id, sim_id) => async (dispatch) => {
  dispatch({
    type: SET_LOADED_SIMULATION_DATA,
    payload: { sim_name, material_id, sim_id }
  })
}

export const saveSimulationInputChanges =
  (current_workspace_id,
    material_id,
    sim_dataset,
    g_zone_input,
    group_info,
    adu_freq,
    ordering_days,
    save_name,
    sim_id) => async (dispatch) => {

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      dispatch({
        type: BEGIN_SIMULATE_FETCH
      })

      const body = JSON.stringify({
        current_workspace_id,
        material_id,
        sim_dataset,
        g_zone_input,
        group_info,
        adu_freq,
        ordering_days,
        save_name,
        sim_id
      });

      try {

        const response = await axiosWrapper.put(
          `/analytics/simulations`, body, config
        )
        dispatch({
          type: SAVE_SIMULATION_DATA,
          payload: response.data
        })

      } catch (error) {
        toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
          autoClose: 3000,
          toastId: "genericError"
        })
      }

      dispatch({
        type: FINISHED_SIMULATE_FETCH
      })

    }

export const changeSimulationResultData = (newSimulationResultData) => async (dispatch) => {
  dispatch({
    type: SET_SIMULATE_WITH_DATA,
    payload: newSimulationResultData
  })
}

export const getSimulationAllowedStatus = (current_workspace_id) => async(dispatch) => {
  try {

    const response = await axiosWrapper.get(
      `/analytics/sim-prep?current_workspace_id=${current_workspace_id}`
    )
    
    dispatch({
      type: GET_SIMULATION_STATUS,
      payload: response.data[0].worksapce_materials
    })

  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      autoClose: 3000,
      toastId: "genericError"
    })
  }
}

export const getAnalyticsStatus = (current_workspace_id)=> async(dispatch) =>{
  try {

    const response = await axiosWrapper.get(
      `/analytics/sim-corollary?current_workspace_id=${current_workspace_id}`
    )
    
    dispatch({
      type: ANALYTICS_ALLOWED_STATUS,
      payload: mapAnalyticsContentDisableStatus(response.data[0].message)
    })

  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      autoClose: 3000,
      toastId: "genericError"
    })
  }
}

export const changeToggleOption = (newOptions) => async(dispatch) =>{
  dispatch({
    type: SET_TOGGLE_OPTIONS,
    payload: newOptions
  })
}

export const changeAnalyticsMaterialData = (currentMaterialData) => async(dispatch)=>{
  dispatch({
    type: SET_ANALYTICS_MATERIAL_DATA,
    payload: currentMaterialData
  })
} 