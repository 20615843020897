import React from "react";

import _ from "lodash";

import axiosWrapper from "../utils/axiosWrapper";

import {
  GET_LAYOUTS,
  SELECT_LAYOUT,
  SAVE_LAYOUT,
  DELETE_LAYOUT,
  EDIT_LAYOUT,
  SELECT_ROW,
  GET_ITEMS,
  UPDATE_ORDER_CALCULATIONS_METADATA,
  BEGIN_TABLE_UPDATE,
  ABORT_TABLE_UPDATE,
  END_TABLE_UPDATE,
  EDIT_ACTUAL_ORDER,
  SET_NEW_CALCULATION_REQUIRED,
  SET_ORDER_ERRORS,
  SET_NOTIFICATION_PANEL_FILTER,
  SET_ORDER_TABLE_FILTERS,
  UPDATE_TABLE_ORDER,
  SELECT_ACTUAL_ORDER_CELL,
  SET_ORDER_TABLE_CONTAINER,
  UPDATE_TABLE_CELL_VALUE,
  START_ACTUAL_ORDER_UPDATE,
  END_ACTUAL_ORDER_UPDATE,
} from "./types";

import { itemAccessors } from "../utils/constants";

import { BASIC_LAYOUT } from "../utils/constants";

import { toast } from "react-toastify";

import ToastI18nWrapper from "../components/ToastI18nWrapper/ToastI18nWrapper";

import {
  hasCalendarEventsChanged,
  hasGroupsChanged,
  hasHistoricalDemandChanged,
} from "../utils/changeWarning";

import {
  mapResultsRecordsToTableData,
  mapOrderTableRecordToTableRow,
  mapCustomOrderTableLayout
} from "../utils/mappers/ordersTable";
import { getWorkspaceTimezone } from "../utils/mappers/workspace";
import { orderItemsByZoneColors } from "../utils/mappers/ordersTable";

export const detectOrderTableParameterChanges = () => (dispatch, getState) => {
  const calendarEventsChanged = hasCalendarEventsChanged(
    getState().ordersTable.calendarEvents,
    getState().calendar.events,
    getState().workspace.currentWorkspace
  );

  const groupsChanged = hasGroupsChanged(
    getState().ordersTable.groups,
    getState().groupSettings.groups
  );

  const historicalDemandChanged = hasHistoricalDemandChanged(
    getState().files.fileTypeInfo.historical_demand.lastUpdated,
    getState().ordersTable.committedAt
  );

  dispatch({
    type: SET_NEW_CALCULATION_REQUIRED,
    payload: { calendarEventsChanged, groupsChanged, historicalDemandChanged },
  });
};

export const updateTableCellByRowAndColumn = (updatedRow, columnId, value) => (dispatch) => {
  updatedRow[columnId] = !isNaN(value) ? Number(value).toFixed(2) : value;

  dispatch({
    type: UPDATE_TABLE_CELL_VALUE,
    payload: {
      item: updatedRow
    }
  });
}

export const updateTableRow = (updatedRow, columnId, value) => (dispatch) => {
  if (columnId === itemAccessors.orderVariance) {
    dispatch({
      type: EDIT_ACTUAL_ORDER,
      payload: {
        ...updatedRow,
        [itemAccessors.orderVariance]: {
          delta: value.delta,
          reasonCode: value.reasonCode,
        },
      },
    });
  } else if (columnId === itemAccessors.actualOrder) {
    let computedVariance =
      parseFloat(value) - parseFloat(updatedRow[itemAccessors.suggestedOrder]);

    if (Number.isNaN(computedVariance)) {
      computedVariance = 0;
    }

    dispatch({
      type: EDIT_ACTUAL_ORDER,
      payload: {
        ...updatedRow,
        [itemAccessors.actualOrder]: value,
        [itemAccessors.orderVariance]: {
          ...updatedRow[itemAccessors.orderVariance],
          delta: computedVariance,
        },
      },
    });
  }
};

export const updateTableCellsAndSync = (
  order_calculation_id,
  workspace_id,
  code,
  location,
  actual_order,
  variance
) => async (dispatch, getState) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const newActualOrder = parseFloat(actual_order).toFixed(2);

  const body = JSON.stringify({
    action: "update",
    current_workspace_id: workspace_id,
    order_calculation_id: order_calculation_id,
    location: location,
    code: code,
    actual_order: newActualOrder,
    reason_code: _.isNil(variance.reasonCode) ? "" : variance.reasonCode,
  });

  try {
    const workspaceTimezone = getWorkspaceTimezone(getState())

    // XXX_ACTUAL_ORDER_UPDATE can be used to determine when to send requests
    // for dynamic fields single calculations to ensure there isn't a race
    // condition when updating the order table data
    dispatch({ type: START_ACTUAL_ORDER_UPDATE });

    dispatch({ type: BEGIN_TABLE_UPDATE });
    const response = await axiosWrapper.put("/order-management", body, config);

    const record = response.data.diff;
    dispatch({
      type: EDIT_ACTUAL_ORDER,
      payload: mapOrderTableRecordToTableRow(record, workspaceTimezone),
    });
    dispatch({
      type: UPDATE_ORDER_CALCULATIONS_METADATA,
      payload: {
        isCommitted: !_.isNil(response.data.committed_at),
        committedAt: response.data.committed_at,
        id: response.data.id,
        calendarEvents: response.data.events.records,
        groups: response.data.groups.records,
      },
    });
    dispatch({ type: END_TABLE_UPDATE });
  } catch (error) {
    dispatch({ type: ABORT_TABLE_UPDATE });
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });
  } finally {
    dispatch({ type: END_ACTUAL_ORDER_UPDATE });
  }
};

export const commit = (orderCalculationId, workspaceId, records) => async (
  dispatch,
  getState
) => {
  if (
    !_.isEmpty(
      records.records.filter(
        (entry) => entry.variance !== 0 && entry.reason_code === ""
      )
    )
  ) {
    toast.error(
      <ToastI18nWrapper translateKey={"ordersTable.genericReasonCodeError"} />,
      { timeout: 4000 }
    );
    dispatch({
      type: SET_ORDER_ERRORS,
      payload: records.records.filter(
        (entry) => entry.variance !== 0 && entry.reason_code === ""
      )
    })
    return;
  }
  dispatch({
    type: SET_ORDER_ERRORS,
    payload: records.records.filter(
      (entry) => entry.variance !== 0 && entry.reason_code === ""
    )
  })

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Add a function that maps the records and checks for '-'.
  //const recordsTransformed = Object.entries(records.records).map((record) => {
  //  return Object.fromEntries(Object.entries(record[1]).map((entry) => {
  //    if (entry[1] === "-") {
  //      return [entry[0], null];
  //    } else {
  //      return entry;
  //    }
  //  }));
  //});
  //
  //const auxRecords = { records: recordsTransformed };
  //
  //records = auxRecords;

  const body = JSON.stringify({
    action: "commit",
    current_workspace_id: workspaceId,
    order_calculation_id: orderCalculationId,
    material_records: records,
  });

  try {
    const workspaceTimezone = getWorkspaceTimezone(getState())

    dispatch({ type: BEGIN_TABLE_UPDATE });
    
    const response = await axiosWrapper.put("/order-management", body, config);
    dispatch({
      type: GET_ITEMS,
      payload: mapResultsRecordsToTableData(response.data.results.records, workspaceTimezone),
    });
    dispatch({
      type: UPDATE_ORDER_CALCULATIONS_METADATA,
      payload: {
        isCommitted: !_.isNil(response.data.committed_at),
        committedAt: response.data.committed_at,
        id: response.data.id,
      },
    });
    dispatch({ type: END_TABLE_UPDATE });
    toast.success(
      <ToastI18nWrapper translateKey={"ordersTable.commitSuccess"} />,
      { timeout: 1000 }
    );
  } catch (error) {
    dispatch({ type: ABORT_TABLE_UPDATE });

    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });
  }
};

export const undoCommit = (orderCalculationId, workspaceId) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    action: "undo_commit",
    current_workspace_id: workspaceId,
    order_calculation_id: orderCalculationId,
  });

  try {
    dispatch({ type: BEGIN_TABLE_UPDATE });
    const response = await axiosWrapper.put("/order-management", body, config);

    dispatch({
      type: UPDATE_ORDER_CALCULATIONS_METADATA,
      payload: {
        isCommitted: !_.isNil(response.data.committed_at),
        committedAt: response.data.committed_at,
        id: response.data.id,
      },
    });
    toast.info(<ToastI18nWrapper translateKey={"ordersTable.commitUndone"} />, {
      timeout: 1000,
    });
  } catch (error) {
    dispatch({ type: ABORT_TABLE_UPDATE });
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });
  }
};

export const getLayouts = (currentWorkspaceId) => async (dispatch) => {
  try {
    const response = await axiosWrapper.get(
      `/order-table-layouts?current_workspace_id=${currentWorkspaceId}`
    );
    dispatch({
      type: GET_LAYOUTS,
      payload: response.data.map(mapCustomOrderTableLayout),
    });
  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });
  }
};

export const saveLayout = (currentWorkspaceId, layout) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    current_workspace_id: currentWorkspaceId,
    name: layout.name,
    initial_state: layout.initialState,
  });
  try {
    const response = await axiosWrapper.post(
      "/order-table-layouts",
      body,
      config
    );
    dispatch({
      type: SAVE_LAYOUT,
      payload: mapCustomOrderTableLayout(response.data),
    });
    toast.success(
      <ToastI18nWrapper translateKey={"ordersTable.layoutSaved"} />,
      {
        timeout: 800,
      }
    );
  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });
  }
};

export const selectLayout = (id) => (dispatch) => {
  dispatch({
    type: SELECT_LAYOUT,
    payload: id,
  });
};

export const deleteLayout = (currentWorkspaceId, layoutId) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      current_workspace_id: currentWorkspaceId,
      id: layoutId,
    }),
  };

  try {
    await axiosWrapper.delete("/order-table-layouts", config);
    dispatch({
      type: DELETE_LAYOUT,
      payload: layoutId,
    });
    dispatch(selectLayout(BASIC_LAYOUT.id));
  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });
  }
};

export const editLayout = (
  currentWorkspaceId,
  tableState,
  currentLayout
) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({
    current_workspace_id: currentWorkspaceId,
    id: currentLayout.id,
    name: currentLayout.name,
    initial_state: tableState,
  });
  try {
    const response = await axiosWrapper.put(
      "/order-table-layouts",
      body,
      config
    );
    dispatch({
      type: EDIT_LAYOUT,
      payload: mapCustomOrderTableLayout(response.data),
    });
  } catch (error) {
    toast.error(<ToastI18nWrapper translateKey={"genericError"} />, {
      toastId: "genericError",
    });
  }
};

export const setSelectedRow = (row) => (dispatch) => {
  dispatch({
    type: SELECT_ROW,
    payload: row,
  });
};

export const setNotificationFilter = (filterArray) => (dispatch) => {
  dispatch({
    type: SET_NOTIFICATION_PANEL_FILTER,
    payload: filterArray,
  });
}

export const setOrderTableAndMenuFilters = (filtersObjectData) => (dispatch) => {
  dispatch({
    type: SET_ORDER_TABLE_FILTERS,
    payload: filtersObjectData,
  });
}

export const updateOrderTableByBufferZoneColor = (items, orderColorArray, t)=> async(dispatch)=>{
  dispatch({
    type: UPDATE_TABLE_ORDER,
    payload: orderItemsByZoneColors(items, orderColorArray, t)
  })
}

export const selectedActualOrderCell = (wasSelected) => (dispatch) => {
  dispatch({
    type: SELECT_ACTUAL_ORDER_CELL,
    payload: wasSelected,
  });
} 

export const changeOrderTableWidth = (width) => (dispatch) => {
  dispatch({
    type: SET_ORDER_TABLE_CONTAINER,
    payload: width,
  });
}
