import React, { useMemo, useRef, useEffect } from 'react'
import { Grid, Segment, Form, Dropdown, Table } from 'semantic-ui-react'
import ModalGroupSetting from '../ModalGroupSetting/ModalGroupSetting'
import ModalGreyZone from '../ModalGreyZone/ModalGreyZone'
import { connect } from 'react-redux'
import { 
            changeSimulationMaterialData, 
            simulateData, 
            getMaterialGroupSetting,
            changeSimulationQuantityModifier,
            changeSimulationMaterialGroupSetting,
            changeSimulationGreyZoneArray,
            changeLoadSimulationData,
            changeInputSimulationDataStatus,
            changeSimulationResultData,
            changeLoadSelectedData
        } from '../../../../actions/analytics'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import useComponentSize from '../../../../hooks/useComponentSize'
import LargeTextPopup from '../../../LargeTextPopup/LargeTextPopup'
import './MaterialSection.css'
import _ from 'lodash'
import { formatComplementaryInfo } from '../../../../utils/mappers/simulation'

const MaterialSection = 
({
    currentWorkspaceId,
    simulationMaterialData, 
    changeSimulationMaterialData,
    getMaterialGroupSetting,
    simulationAduFreqUpd,
    simOrdDays,
    complementaryInfo,
    simulateData,
    changeSimulationQuantityModifier,
    changeSimulationMaterialGroupSetting,
    changeSimulationGreyZoneArray,
    dateRange,
    disabled,
    changeLoadSimulationData,
    changeInputSimulationDataStatus,
    changeSimulationResultData,
    changeLoadSelectedData,
    loadSimulationData,
    materialDataList,
    disableAnalyticsContentInteraction,
    onMaterialSelected,
})=>{

    const { t } = useTranslation()
    const componentRef = useRef(null)
    const { componentWidth } = useComponentSize(componentRef)

    const materialOptions = useMemo(()=>{
        if(!disableAnalyticsContentInteraction && !_.isEmpty(materialDataList)){
            return materialDataList
                    .map(material=>{ 
                        // Remove last parenthesis, then slice from las indexof "(" to material.length
                        let locationCode = material.slice(0, material.length-1)
                        locationCode = material
                                            .slice(material.lastIndexOf("("), material.length)
                                            .replace(/[()]/g, "")

                    return {
                        key: material,
                        value: `${material}:${locationCode}`,
                        text: material,
                    }
                    })
        }
        return []
    }, [ materialDataList, disableAnalyticsContentInteraction])

    const runSimulationWithMaterialData = (selectedValue)=>{
        const currentMaterialIdData = selectedValue.value.split(':')[1]
        if (onMaterialSelected) {
            onMaterialSelected(currentMaterialIdData);
        }
        const sim_data=[], g_zone_input=[], group_info={}
        if(simulationMaterialData.name !== currentMaterialIdData){
            changeSimulationQuantityModifier(sim_data)
            changeSimulationMaterialGroupSetting(group_info)
            changeSimulationGreyZoneArray(g_zone_input)
        }


        changeSimulationMaterialData({
            name: selectedValue.value.split(':')[0],
            data: selectedValue.value
        })
        
        simulateData(
            currentWorkspaceId, 
            currentMaterialIdData,
            sim_data,
            g_zone_input, 
            group_info,  
            simulationAduFreqUpd,
            simOrdDays,
            )
        getMaterialGroupSetting(currentWorkspaceId, currentMaterialIdData)

        //Reset loaded value to show new simulation data and simulation data status
        changeLoadSimulationData([])
        changeInputSimulationDataStatus(true)
        changeSimulationResultData({})
        changeLoadSelectedData({})
    }

    const complementaryInfoReceived = useMemo(()=>{
        if(loadSimulationData.length){
            return loadSimulationData[0].loaded_results.complementary_info
        }
        if(_.isEmpty(complementaryInfo)){
            return {minimum_order_quantity: "-", multiple_order_quantity: "-", supply_lead_time: "-", unit_cost: "-"}
        }
        return complementaryInfo

    }, [ loadSimulationData, complementaryInfo])

    useEffect(()=>{
        if (complementaryInfo) {
            formatComplementaryInfo(complementaryInfo)
        }
    }, [complementaryInfo])

    return(
    <Grid.Column mobile={4} widescreen={5} tablet={4}>
        <div ref={componentRef}>
            <Segment>
                <Form.Field className='material-section' required>
                <label className="field-title">{t('simulation.materialTitle')}</label>
                <Dropdown 
                    placeholder={t("simulation.itemDescription")}
                    fluid
                    value={loadSimulationData.length ? "" : simulationMaterialData.data}
                    onChange={(event, selectedValue)=>{
                            runSimulationWithMaterialData(selectedValue);
                        }}
                    disabled={disableAnalyticsContentInteraction}
                    search selection options={materialOptions}
                    />
                </Form.Field>
                {/* TODO: temporal solution refactor this table code later */}
                <Form.Field className='material-section'>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell textAlign='center'>
                                    {t("simulation.minimumOrderQuantity")}
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>
                                    {t("simulation.multipleOrerQuantity")}
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>
                                    {t("simulation.supplyLeadTime")}
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>
                                    {t("simulation.unitCost")}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell textAlign='center'>
                                    {<LargeTextPopup 
                                        content={String(complementaryInfoReceived.minimum_order_quantity)}
                                        containerWidth={componentWidth}
                                        wordLength={0.019}
                                        textStyle="paragraph"/>}
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {<LargeTextPopup 
                                        content={String(complementaryInfoReceived.multiple_order_quantity)}
                                        containerWidth={componentWidth}
                                        wordLength={0.019}
                                        textStyle="paragraph"/>}
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {complementaryInfoReceived.supply_lead_time}
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {complementaryInfoReceived.unit_cost}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Form.Field>
                <Form.Field className='material-section' disabled={disabled}>
                    <ModalGroupSetting   
                        disabled={disabled} 
                        changeGroupSetting={(newGroupSettings)=>{changeSimulationMaterialGroupSetting(newGroupSettings)}}
                        />
                </Form.Field>
                <Form.Field className='material-section' disabled={disabled}>
                    <ModalGreyZone 
                        disabled={disabled} 
                        changeGreyZone={(newGreyZoneArray)=>{changeSimulationGreyZoneArray(newGreyZoneArray)}}
                        dateRange={dateRange} 
                        />
                </Form.Field>
            </Segment>
        </div>
    </Grid.Column>
    )
}

MaterialSection.propTypes = {
    currentWorkspaceId: PropTypes.number,
    simulationMaterialData: PropTypes.object,
    changeSimulationMaterialData: PropTypes.func,
    getMaterialGroupSetting: PropTypes.func,
    simulationAduFreqUpd: PropTypes.number,
    simOrdDays: PropTypes.array,
    complementaryInfo: PropTypes.object,
    simulateData: PropTypes.func,
    changeSimulationQuantityModifier: PropTypes.func,
    changeSimulationMaterialGroupSetting: PropTypes.func,
    changeSimulationGreyZoneArray: PropTypes.func,
    dateRange: PropTypes.object,
    disabled: PropTypes.bool,
    changeLoadSimulationData: PropTypes.func,
    changeInputSimulationDataStatus: PropTypes.func,
    changeSimulationResultData: PropTypes.func,
    changeLoadSelectedData: PropTypes.func,
    loadSimulationData: PropTypes.array,
    materialDataList: PropTypes.array,
    disableAnalyticsContentInteraction: PropTypes.bool,
    onMaterialSelected: PropTypes.func,
}

const mapStateToProps = (state)=>({
    simulationMaterialData: state.analytics.simulationMaterialData,
    simulationAduFreqUpd: state.analytics.simulationAduFreqUpd,
    simOrdDays: state.analytics.simOrdDays,
    complementaryInfo: state.analytics.complementaryInfo,
    loadSimulationData: state.analytics.loadSimulationData,
    materialDataList: state.analytics.materialDataList,
    disableAnalyticsContentInteraction: state.analytics.disableAnalyticsContentInteraction
})

export default connect( mapStateToProps, 
                        {
                            simulateData, 
                            changeSimulationMaterialData, 
                            getMaterialGroupSetting, 
                            changeSimulationQuantityModifier, 
                            changeSimulationMaterialGroupSetting,
                            changeSimulationGreyZoneArray,
                            changeLoadSimulationData,
                            changeInputSimulationDataStatus,
                            changeSimulationResultData,
                            changeLoadSelectedData
                        })(MaterialSection)