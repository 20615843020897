import React, { useMemo, useState, useEffect } from "react"
import { connect } from "react-redux"
import { Grid, Form, Dropdown, Segment, Button, Input } from "semantic-ui-react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import FiltersDropdown from "../FiltersDropdown/FiltersDropdown"
import WorkspaceDropdown from "../WorkspaceDropdown/WorkspaceDropdown"
import { queryReport, setFieldFilterProperties } from "../../../../actions/analytics-v2"
import { getSafeSelectedWorkspace } from "../../../../utils/mappers/user"
import { changeAnalyticsMaterialData,
        changeToggleOption,
    } from "../../../../actions/analytics-v2"
import "./ChartModifier.css"

const ChartModifier = 
({ analyticsToggleOptions, 
    currentWorkspaceId,
    analyticsMaterialData,
    changeAnalyticsMaterialData,
    queryReport,
    materialDataList,
    allWorkspacesFieldFilters, 
    setFieldFilterProperties,
    selectedAllWorkspacesFiedFilter,
    workspaces,
    // Props passed from AnalyticsSection
    selectedWorkspaces,
    setSelectedWorkspaces,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    haveFiltersChanged,
    applyDateFilters,
    hasExpandableFilterChanges,
    setHasExpandableFilterChanges
})=>{
    const { wasAggregateSelected } = analyticsToggleOptions
    const { t } = useTranslation()

    // Add local state to track date changes
    const [localDateChanges, setLocalDateChanges] = useState(false);
    const [initialFromDate, setInitialFromDate] = useState(fromDate);
    const [initialToDate, setInitialToDate] = useState(toDate);
    // Add local state to track workspace selection changes
    const [localWorkspaceChanges, setLocalWorkspaceChanges] = useState(false);
    const [, setInitialWorkspaces] = useState(selectedWorkspaces);
    // Track changes to field filters as a separate state to ensure they persist when the filter section is collapsed
    const [localFilterChanges, setLocalFilterChanges] = useState(false);

    const groupFields = allWorkspacesFieldFilters

    const updateMarkedFields = (accessor, value) => {
        const newMappedGroupFields = groupFields.map((field) => {
            if (field.accessor === accessor) {
                return { ...field, isChecked: value };
            }
            return field;
        });
        
        // Update the field filters but don't reset any existing filter changes
        setFieldFilterProperties(newMappedGroupFields, true);
        
        // Make sure we track that a change occurred that needs to be applied
        if (!localWorkspaceChanges && !localDateChanges && !localFilterChanges && haveFiltersChanged === false) {
            setLocalFilterChanges(true);
        }
    };

    const handleWorkspaceSelectionChange = (selectedWorkspaceIds) => {
        // Just update the selectedWorkspaces state, don't trigger queryReport
        setSelectedWorkspaces(selectedWorkspaceIds);
        // Set localWorkspaceChanges to true
        setLocalWorkspaceChanges(true);
    }

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
        // If date changed from initial value, set localDateChanges to true
        if (e.target.value !== initialFromDate) {
            setLocalDateChanges(true);
        } else if (toDate === initialToDate) {
            // If both dates reverted to initial values, reset localDateChanges
            setLocalDateChanges(false);
        }
    }

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
        // If date changed from initial value, set localDateChanges to true
        if (e.target.value !== initialToDate) {
            setLocalDateChanges(true);
        } else if (fromDate === initialFromDate) {
            // If both dates reverted to initial values, reset localDateChanges
            setLocalDateChanges(false);
        }
    }

    // Format dates for API
    const formatDateForApi = (dateStr) => {
        if (!dateStr) return '';
        const date = new Date(dateStr);
        return date.toISOString();
    };

    // Handle apply button click
    const handleApplyClick = () => {
        // Only apply the date filters when in aggregate view or when a material is selected
        if (wasAggregateSelected || analyticsMaterialData) {
            applyDateFilters(fromDate, toDate);
        }
        
        // Reset our tracking of date changes
        setLocalDateChanges(false);
        setInitialFromDate(fromDate);
        setInitialToDate(toDate);
        
        // Reset workspace changes tracking
        setLocalWorkspaceChanges(false);
        setInitialWorkspaces(selectedWorkspaces);
        
        // Reset filter changes tracking
        setLocalFilterChanges(false);
        
        // Reset expandable filter changes - we'll reset this only after making the API call
        const shouldResetExpandableFilters = setHasExpandableFilterChanges && (hasExpandableFilterChanges || localFilterChanges);
        
        // Always make the API call when there are filter, date, or workspace changes
        // This ensures removes are processed too
        if (wasAggregateSelected) {
            if (haveFiltersChanged !== false || localDateChanges || localWorkspaceChanges || localFilterChanges || hasExpandableFilterChanges) {
                queryReport({
                    currentWorkspaceId,
                    selectedWorkspaces: selectedWorkspaces,
                    earliestDatetime: formatDateForApi(fromDate),
                    latestDatetime: formatDateForApi(toDate),
                    t, 
                    fieldFilters: selectedAllWorkspacesFiedFilter,
                    allWorkspaces: true
                });
                
                // Only reset expandable filter changes after the API call is made
                if (shouldResetExpandableFilters) {
                    setHasExpandableFilterChanges(false);
                }
            }
        } else {
            // For single material view, only apply the date filter if a material is selected
            if (analyticsMaterialData) {
                // We don't need to make a new API call since applying the date filters will
                // automatically update the chart visualization through the applyDateFilters function
                
                // Still reset expandable filter changes
                if (shouldResetExpandableFilters) {
                    setHasExpandableFilterChanges(false);
                }
            }
        }
    };

    const handleAnalyticsByMaterial = (materialData)=>{
        const currentMaterialIdData = materialData.value.split(':')[1]
        // Check if I have to send a correct param of no 
        // const fieldFilters = { ...newGroupData}
        changeAnalyticsMaterialData(materialData.value)
        queryReport({
                    currentWorkspaceId,
                    selectedWorkspaces: [currentWorkspaceId], 
                    t, 
                    fieldFilters: selectedAllWorkspacesFiedFilter, 
                    materialId: currentMaterialIdData,
                    allWorkspaces: false
                });
    }

    const materialOptions = useMemo(()=>{
        if(materialDataList){
            return materialDataList
                    .map(material=>{ 
                        // Remove last parenthesis, then slice from las indexof "(" to material.length
                        let locationCode = material.slice(0, material.length-1)
                        locationCode = material
                                            .slice(material.lastIndexOf("("), material.length)
                                            .replace(/[()]/g, "")

                        return {
                            key: material,
                            value: `${material}:${locationCode}`,
                            text: material,
                        }
                    })
        }
        return []
    }, [ materialDataList ])

    // Initialize active date filters when a material is first selected
    useEffect(() => {
        if (!wasAggregateSelected && analyticsMaterialData && fromDate && toDate) {
            // Only initialize once when material is first selected
            applyDateFilters(fromDate, toDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wasAggregateSelected, analyticsMaterialData]);

    // Initialize initialWorkspaces when component mounts
    useEffect(() => {
        setInitialWorkspaces(selectedWorkspaces);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Segment>
            <Grid.Row className="filter-row">
            {
                wasAggregateSelected
                    ?   (
                            <Grid.Column>
                                <Grid stackable>
                                    <Grid.Row className="no-padding">
                                        <Grid.Column width={6} className="filter-group">
                                            <Form.Field>
                                                <label>{t("groupSettings.filters")}</label>
                                                {groupFields && <FiltersDropdown
                                                    className="group-fields-dropdown"
                                                    groupFields={groupFields}
                                                    markedGroupFields={groupFields
                                                                    .map((field) => field.accessor)}
                                                    onChange={(accesor, isChecked)=> {updateMarkedFields(accesor, isChecked)}}
                                                />}
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={6} className="filter-group">
                                            <Form.Field>
                                                <label>{t("analytics.workspaces")}</label>
                                                <WorkspaceDropdown
                                                    className="workspace-dropdown"
                                                    workspaces={workspaces}
                                                    selectedWorkspaces={selectedWorkspaces}
                                                    onChange={handleWorkspaceSelectionChange}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    
                                    <Grid.Row className="no-p-top">
                                        <Grid.Column width={6} className="filter-group">
                                            <Form.Field>
                                                <label>{t("analytics.fromDate")}</label>
                                                <Input 
                                                    type="date"
                                                    value={fromDate}
                                                    onChange={handleFromDateChange}
                                                    fluid
                                                    className="date-input"
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={6} className="filter-group">
                                            <Form.Field>
                                                <label>{t("analytics.toDate")}</label>
                                                <Input 
                                                    type="date"
                                                    value={toDate}
                                                    onChange={handleToDateChange}
                                                    fluid
                                                    className="date-input"
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={4} verticalAlign="bottom" className="filter-group apply-button-column">
                                            <Button 
                                                primary
                                                fluid
                                                className="apply-button"
                                                onClick={handleApplyClick}
                                                disabled={!wasAggregateSelected ? 
                                                    (!analyticsMaterialData && !localDateChanges) : 
                                                    (haveFiltersChanged === false && !localDateChanges && !localWorkspaceChanges && !localFilterChanges && !hasExpandableFilterChanges)}
                                            >
                                                {t("analytics.apply")}
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        )
                    :   (
                            <Grid.Column>
                                <Grid stackable>
                                    <Grid.Row className="no-padding">
                                        <Grid.Column width={12} className="filter-group">
                                            <Form.Field className='material-section' required>
                                                <label className="field-title">{t('simulation.materialTitle')}</label>
                                                <Dropdown
                                                    placeholder={t("simulation.itemDescription")}
                                                    value={analyticsMaterialData}
                                                    onChange={(event, selectedMaterial)=>{
                                                        // Just load the material data without applying date filters
                                                        handleAnalyticsByMaterial(selectedMaterial);
                                                    }}
                                                    compact
                                                    search 
                                                    selection 
                                                    disabled={materialOptions.length ? false : true}
                                                    options={materialOptions}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                    
                                    <Grid.Row className="no-p-top">
                                        <Grid.Column width={6} className="filter-group">
                                            <Form.Field>
                                                <label>{t("analytics.fromDate")}</label>
                                                <Input 
                                                    type="date"
                                                    value={fromDate}
                                                    onChange={handleFromDateChange}
                                                    fluid
                                                    className="date-input"
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={6} className="filter-group">
                                            <Form.Field>
                                                <label>{t("analytics.toDate")}</label>
                                                <Input 
                                                    type="date"
                                                    value={toDate}
                                                    onChange={handleToDateChange}
                                                    fluid
                                                    className="date-input"
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={4} verticalAlign="bottom" className="filter-group apply-button-column">
                                            <Button 
                                                primary
                                                fluid
                                                className="apply-button"
                                                onClick={handleApplyClick}
                                                disabled={!wasAggregateSelected ? 
                                                    (!analyticsMaterialData && !localDateChanges) : 
                                                    (haveFiltersChanged === false && !localDateChanges && !localWorkspaceChanges && !localFilterChanges && !hasExpandableFilterChanges)}
                                            >
                                                {t("analytics.apply")}
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        )
            }
            </Grid.Row>
        </Segment>
    )
}

ChartModifier.propTypes = {
    analyticsToggleOptions: PropTypes.object,
    currentWorkspaceId: PropTypes.number,
    analyticsMaterialData: PropTypes.string,
    changeAnalyticsMaterialData: PropTypes.func,
    queryReport: PropTypes.func,
    materialDataList: PropTypes.array,
    user: PropTypes.object,
    disableAnalyticsContentInteraction: PropTypes.bool,
    changeToggleOption: PropTypes.func,
    byMaterialTimeFilter: PropTypes.array,
    workspaces: PropTypes.array,
    currentWorkspaceFieldFilters: PropTypes.array,
    allWorkspacesFieldFilters: PropTypes.array,
    setFieldFilterProperties: PropTypes.func,
    selectedCurrentWorkspaceFieldFilter: PropTypes.object,
    selectedAllWorkspacesFiedFilter: PropTypes.object,
    // Props received from AnalyticsSection
    selectedWorkspaces: PropTypes.array,
    setSelectedWorkspaces: PropTypes.func,
    fromDate: PropTypes.string,
    setFromDate: PropTypes.func,
    toDate: PropTypes.string,
    setToDate: PropTypes.func,
    temporalFieldFilters: PropTypes.object,
    haveFiltersChanged: PropTypes.bool,
    applyDateFilters: PropTypes.func,
    hasExpandableFilterChanges: PropTypes.bool,
    setHasExpandableFilterChanges: PropTypes.func,
}

const mapStateToProps = (state)=>({
    analyticsToggleOptions: state.analytics.analyticsToggleOptions,
    currentWorkspaceId: getSafeSelectedWorkspace(state),
    analyticsMaterialData: state.analytics.analyticsMaterialData,
    materialDataList: state.analytics.materialDataList,
    user: state.auth.user,
    disableAnalyticsContentInteraction: state.analytics.disableAnalyticsContentInteraction,
    byMaterialTimeFilter: state.analytics.byMaterialTimeFilter,
    workspaces: state.workspace.workspaces,
    currentWorkspaceFieldFilters: state.analytics.currentWorkspaceFieldFilters,
    allWorkspacesFieldFilters: state.analytics.allWorkspacesFieldFilters,
    selectedCurrentWorkspaceFieldFilter: state.analytics.selectedCurrentWorkspaceFieldFilter,
    selectedAllWorkspacesFiedFilter: state.analytics.selectedAllWorkspacesFiedFilter,
})

export default connect(mapStateToProps, { 
                                            queryReport, 
                                            changeAnalyticsMaterialData, 
                                            changeToggleOption,
                                            setFieldFilterProperties
                                         })(ChartModifier)