import { toExcelNumberFormatWithDecimals } from "../format";


export const formatComplementaryInfo = (
    simulationData
) => {
    if (!simulationData) return;
    
    Object.keys(simulationData).forEach((key) => {
        simulationData[key] = getFormattedEntry(simulationData[key])
     
    })

};

const extractUnits = (numberWithUnits) => {
    if(typeof(numberWithUnits) !== 'string') return '';
    const unitPattern = /\s*([A-Za-z]*)/gi;
    return [...numberWithUnits.matchAll(unitPattern)].map((match) => match[0]).join('')

}

const getFormattedEntry = (objEntry) => {
    const numberPattern = /[0-9]*\.[0-9]*|[0-9]*/
    if(typeof(objEntry) === 'string'){
        const units = extractUnits(objEntry)
        const formattedNumber = toExcelNumberFormatWithDecimals(objEntry.match(numberPattern)[0]) 
        objEntry = units.length > 0 ? 
        formattedNumber.concat(' ').concat(units) : toExcelNumberFormatWithDecimals(objEntry.match(numberPattern)[0])
        
    } else {
        objEntry = toExcelNumberFormatWithDecimals(objEntry)
    }
    return objEntry
    
}


export const formatSimulationResultsData = (resultsData) => {
    if (!resultsData) return
    const contentTags = ['planned', 'actual']
    resultsData.forEach(tableRow => {
        contentTags.forEach(tag => {
            tableRow[tag] = getFormattedEntry(tableRow[tag])
        })
    })
}

export const formatSimulationPerformanceData = (performanceData) => {
    if (!performanceData) return
    const excludeTag = 'service_level'
    performanceData.forEach(tableRow => {
        if(!Object.keys(tableRow).includes(excludeTag)){
            Object.keys(tableRow).forEach((entry) => {
                tableRow[entry] = getFormattedEntry(tableRow[entry])
            })
        }
    })
}