import _ from "lodash";

import uuid from "uuid";

import { defaultIgnoredColumnCodes, requiredColumnsIdOrder } from "../constants";

export const mapItemsToUniqueValues = (items, key) => {
  const mapped = items.map((item) => item[key]);

  return _.uniq(mapped);
};

export const mapItemsToDropdownOptions = (items, key, groupType = 'conventional') => {
  if (groupType === 'conventional') {
    return mapItemsToUniqueValues(items, key)
      .filter((value) => !_.isNil(value))
      .map((value) => ({
        key: uuid.v4(),
        text: value,
        value: value,
      }));
  }
  if (groupType === 'event') {
    return mapItemsToUniqueValues(items, key)
      .filter((value) => !_.isNil(value))
      .map((value) => ({
        key: uuid.v4(),
        text: value + ' (' + items.filter(
          row => row.location_code_concatenation === value)[0].material_description + ')',
        value: value,
      }));


  }
};




export const mapGroupsToDropdownOptions = (groups) => {
  return groups.map((group) => {
    let name;
    if (_.isEmpty(group.name) || group.name === "") {
      name = `Group ${group.number}`;
    } else {
      name = { id: group.id, name: group.name };
    }
    return {
      key: group.id,
      text: name,
      value: name,
    };
  });
};

export const getTag = (value) => {
  let text;
  if (value > 0.9) {
    text = "Extremely High";
  } else if (value > 0.7) {
    text = "Very High";
  } else if (value > 0.5) {
    text = "High";
  } else if (value > 0.3) {
    text = "Medium";
  } else if (value > 0.1) {
    text = "Low";
  } else {
    text = "Very Low";
  }
  return `${text} (${value})`;
};

export const mapBufferSettingGroup = (group) => {
  const parsedFilters = group.filters;

  return {
    ...parsedFilters,
    id: group.id,
    name: group.name,
    description: group.description,

    rules: {
      materialMethod: group.material_method,
      aduMeasurementStart: group.adu_measurement_start,
      aduMeasurementEnd: group.adu_measurement_end,
      variabilityFactor: group.variability_factor,
      leadTimeFactor: group.lead_time_factor,
    },
  };
};

export const mapToFilterDropdownOptions = (materialFields) =>
  materialFields.map((materialField) => ({
    key: materialField.accessor,
    text: materialField.name,
    value: materialField.accessor,
  }));

export const mapColumnsToGroupFields = (t, columns, items, groupType = 'conventional') => {
  const groupFields = [];
  let ignoredColumnCodes = [...defaultIgnoredColumnCodes]
  let filteringFields = []
  if (groupType === 'conventional') {
    filteringFields = ["material_location", "material_unitof_measure", "material_type"]
    if (items.length !== 0) {
      // adding columns read from orders table
      const expectedFieldsFromItems = ["supply_lead_time_category", "variability_category"]
      expectedFieldsFromItems.forEach(field => {
        if (Object.keys(items[0]).includes(field)) {
          filteringFields.push(field)
          columns[field] = field
        }
      })
    }
  }

  if (groupType === 'event') {
    filteringFields = ["location_code_concatenation"]
    const userAditionalCols = Object.keys(columns).filter(field => !requiredColumnsIdOrder.includes(field))
    const aditionalIgnoreFields = ["material_location", "material_unitof_measure", "material_type"]
    ignoredColumnCodes = [...ignoredColumnCodes, ...aditionalIgnoreFields, ...userAditionalCols]
    ignoredColumnCodes.splice(ignoredColumnCodes.indexOf('location_code_concatenation'), 1)
  }

  if (!_.isEmpty(columns)) {
    groupFields.push(
      ...Object.keys(columns)
        .filter((key) => !_.includes(ignoredColumnCodes, key))
        .map((key) => ({
          name: _.includes(
            filteringFields,
            key
          )
            ? t(`ordersTable.columns.${key}`)
            : columns[key],
          accessor: key,
          options: mapItemsToDropdownOptions(items, key, groupType),
        }))
    );
  }

  return groupFields;
};

export const mapColsToAdditionalFields = (columns) => {
  return Object.keys(columns).filter(field => !requiredColumnsIdOrder.includes(field))

}

export const fatherItems = (fatherGroup, items, columns) => {
  if (fatherGroup == null) {
    return items
  }
  const additionalColsFields = mapColsToAdditionalFields(columns)
  const includeFields = ["material_type", "material_location", "material_unitof_measure", ...additionalColsFields]
  const fatherFilters = Object.entries(fatherGroup).filter(
    (filterField) => includeFields.includes(filterField[0])).filter(
      (filterField) => !filterField[1].values.includes("all"));

  let filteredItems = []


  const filterItems = () => {
    items.forEach(item => {
      const itemBelongsToFatherFilter = (item, fatherFilter) => {
        return (fatherFilter[1].values.includes(item[fatherFilter[0]]))
      }
      let fulfillsFiltersCriteria = []
      fatherFilters.forEach(fatherFilter => {
        fulfillsFiltersCriteria.push(itemBelongsToFatherFilter(item, fatherFilter))
      })
      if (fulfillsFiltersCriteria.every(filterOcurrance => filterOcurrance === true)) {
        filteredItems.push(item)
      }
    })
  }
  filterItems()
  return filteredItems
}

export const mapChildToFatherMaterials = (
  groups,
  columns,
  fatherGroupsIds,
  items,
  groupData,
  editGroup,
  currentWorkspaceId,
  isOrdersTableCommitted
) => {
  const childGroups = groups.filter(group => group.rules.materialMethod.includes('event') || group.rules.materialMethod.includes('rules'))
  const updateGroup = async (childGroup) => {
    await editGroup(
      currentWorkspaceId,
      childGroup,
      isOrdersTableCommitted
    );
  }
  childGroups.map(childGroup => {

    let fatherMaterials = []

    fatherItems(groupData, items, columns).forEach(
      row => fatherMaterials.push(row.location_code_concatenation))

    let mustEditChild = false
    let materialsForElimination = []

    childGroup.location_code_concatenation.values.forEach(materialId => {
      if (!fatherMaterials.includes(materialId)) {
        materialsForElimination.push(materialId)
        mustEditChild = true
      }
    })
    let completeSubGrEliminated = false
    if (materialsForElimination.length === childGroup.location_code_concatenation.values.length) {
      completeSubGrEliminated = true
    }
    const newMaterials = childGroup.location_code_concatenation.values.filter(
      materialId => fatherMaterials.includes(materialId))

    if (mustEditChild && !completeSubGrEliminated) {
      childGroup.location_code_concatenation.values = newMaterials
      updateGroup(childGroup)
    }
    return null
  })
  return null
};

export const superGroupExists = (selectedGroupsIds, groups) => {
  const selectedGroups = groups.filter(group => selectedGroupsIds.includes(group.id))
  const childGroups = selectedGroups.filter(group => group.rules.materialMethod.includes('event') || group.rules.materialMethod.includes('rules'))
  const conventionalGroups = selectedGroups.filter(group => !group.rules.materialMethod.includes('event') && !group.rules.materialMethod.includes('rules'))
  let superGroupExists = false

  conventionalGroups.map(group => {
    childGroups.map(childGroup => {
      if (childGroup.rules.materialMethod.split('-')[1] === (group.id).toString()) {
        superGroupExists = true
      }
      return null
    })
    return null
  })
  return superGroupExists
}

export const combineEventRulesMirrorSubgroups = (groups) => {
  let rulesSubgroups = []
  let eventSubgroups = []
  groups.forEach(group => {
    const materialMethodDetail = group.rules.materialMethod.split('-')[0]
    if (materialMethodDetail === 'event') {
      eventSubgroups.push(group)
    }
    if (materialMethodDetail === 'rules') {
      rulesSubgroups.push(group)
    }
  })
  let mirrorEventSubgroupIds = []
  rulesSubgroups.forEach(rulesSubgroup => {
    eventSubgroups.forEach(eventSubgroup => {
      if ((Object.keys(eventSubgroup)).includes('location_code_concatenation')) {
        const rulesSubgroupMaterials = rulesSubgroup.location_code_concatenation.values
        const eventSubgroupMaterials = eventSubgroup.location_code_concatenation.values
        const isMirrorSubgroup = eventSubgroupMaterials.filter(
          material =>
            rulesSubgroupMaterials.includes(material)).length === eventSubgroupMaterials.length &&
          rulesSubgroupMaterials.length === eventSubgroupMaterials.length
        if (isMirrorSubgroup) {
          rulesSubgroup['mirrorEventSubgroup'] = eventSubgroup
          mirrorEventSubgroupIds.push(eventSubgroup.id)

        }
      }
    })
  })
  const combinedSubgroups = [...rulesSubgroups,
  ...eventSubgroups.filter(subgroup => !mirrorEventSubgroupIds.includes(subgroup.id))]
  return combinedSubgroups
}