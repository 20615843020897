import React, { useState } from "react";
import PropTypes from "prop-types";
import { toExcelNumberFormatWithDecimals } from "../../../utils/format";
import "./ActualOrderCell.css";
import { maskTextToExtractFloat } from "../../../utils/format";
import { connect } from "react-redux";
import { selectedActualOrderCell } from "../../../actions/ordersTable";

// The implementation of this editable cell was taked from react table docs check:
// https://react-table-v7.tanstack.com/docs/examples/editable-data 

const ActualOrderCell = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  updateTableData, // This is a custom function that we supplied to our table instance
  selectedActualOrderCell,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);
  const [changed, setChanged] = useState(false);
  const temporalInput = document.getElementById(`actual-order-input+${index}`)


  const onChange = (e) => {
    let newValue = maskTextToExtractFloat(e.target.value);
    if(newValue.includes(',')){
      newValue = newValue.replace(',', '')
    }
    if (Number.isNaN(parseFloat(newValue)) && newValue !== "") {
      return;
    }
    if (!changed) {
      setChanged(true);
    }
    setValue(newValue);
  };

  const submitChange = (newValue, performRequest = true) => {
    if (newValue === "" && performRequest) {
      setValue(0);
      updateTableData(index, id, 0, performRequest);
    } else if (newValue === "" && !performRequest) {
      updateTableData(index, id, 0, performRequest);
    } else {
      updateTableData(index, id, newValue, performRequest);
    }
  };

  React.useEffect(() => {
    setValue(toExcelNumberFormatWithDecimals(initialValue));
  }, [initialValue]);


  const keyPressedHandler = (event)=>{
    if(event.key === 'Enter'){
      temporalInput.focus();
      temporalInput.blur();
      selectedActualOrderCell(false)
    }
  }

  return (
    <div className="ui input actual-order">
      <input
        id={`actual-order-input+${index}`}
        className="actual-order"
        type="text"
        value={value}
        key={id}
        onChange={onChange}
        onBlur={() => {
          if (changed) {
            submitChange(value);
            setChanged(false);
          }
          selectedActualOrderCell(false)
        }}
        onKeyPress={(event) => {keyPressedHandler(event)}}
        onClick={() => {selectedActualOrderCell(true)}}
      />
    </div>
  );
};

ActualOrderCell.propTypes = {
  cell: PropTypes.object,
  row: PropTypes.object,
  column: PropTypes.object,
  updateTableData: PropTypes.func,
  selectedActualOrderCell: PropTypes.func,
};

export default connect(null, { selectedActualOrderCell })(ActualOrderCell);
