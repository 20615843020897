import { useTranslation } from "react-i18next";
import {  useMemo } from "react";
import { mapChartCodesToStringsAnalytics } from "../utils/mappers/analytics";
import _ from "lodash"


const useAnalyticsChartDataConvertor = (analyticChartsData)=>{
    const { t, i18n } = useTranslation()    
    const convertAnalyticsChartDataArrayToLibraryFormat = ()=>{
        const newAnalyticsChartsData = {}
        Object
        .keys(analyticChartsData)
        .forEach((chartKey)=>{
            const analyticChartData = analyticChartsData[chartKey]
            if(!_.isEmpty(analyticChartData)){
                const { chartOne, chartTwo } = analyticChartData
                return newAnalyticsChartsData[chartKey] = mapChartCodesToStringsAnalytics({t, chartOne, chartTwo})
                
            }
            return newAnalyticsChartsData[chartKey] = {}
        })  
        return newAnalyticsChartsData
    }

    const convertedAnalytiChartsData = useMemo(()=>{
        return convertAnalyticsChartDataArrayToLibraryFormat()
        // This line was added because when language changed translation and mapper should be run
        // eslint-disable-next-line
    }, [i18n.language, analyticChartsData, convertAnalyticsChartDataArrayToLibraryFormat])

    return convertedAnalytiChartsData

}

export default useAnalyticsChartDataConvertor