import { TIME_FILTER_OPTIONS } from "../../utils/constants"

export const getUserSelectedTimeFilterByCase = (analyticsUserSelectedTimeFilter, optionType)=>{
    const { ALL_WORKSPACES } = TIME_FILTER_OPTIONS;
    const selectedValue = analyticsUserSelectedTimeFilter[optionType].value 
                                ? analyticsUserSelectedTimeFilter[optionType].value 
                                : 0
    const timeFilter = {}
    if(selectedValue && optionType === ALL_WORKSPACES){
        if(analyticsUserSelectedTimeFilter[optionType]['diffmonth']){
            timeFilter["system_value"] = analyticsUserSelectedTimeFilter[optionType]['diffmonth']
            return timeFilter
        }
    }

    if(selectedValue){
        analyticsUserSelectedTimeFilter[optionType]['user_value']
            ? timeFilter["user_value"] = selectedValue
            : timeFilter["system_value"]= selectedValue
    }
    return timeFilter
}

// In the UI, the "all" option sometimes gets put into the dropdown Selection, so we're removing it here
// Also, when the array is empty, just remove the whole filter, because a user could assume that translates to
// no filter, than the no values, which would always return no values 
export const cleanFilters = (filters)=>{
    const cleanedFilters = {...filters}; // Create a copy to avoid modifying the original
    
    for(const property in cleanedFilters){
        if(cleanedFilters[property].values.length === 1 && cleanedFilters[property].values[0] === "all"){
            delete cleanedFilters[property];
        }
        else if(cleanedFilters[property].values.length === 0){
            delete cleanedFilters[property];
        }
    }
    return cleanedFilters;
}